// import SDKService from '../../services/sdk.service'

export default {
    namespaced: true,

    state: {
        scene: null,

        persona: null,

        remoteVideo: null,
    },

    getters: {
        getScene(state) {
            return state.scene;
        },

        getPersona(state) {
            return state.persona;
        },

        getRemoteVideo(state) {
            return state.remoteVideo;
        },
    },

    actions: {
        async setScene({ commit }, scene) {
            commit('setScene', scene)
        },

        async setPersona({ commit }, persona) {
            commit('setPersona', persona)
        },

        async setRemoteVideo({ commit }, remoteVideo) {
            commit('setRemoteVideo', remoteVideo)
        },
    },

    mutations: {
        setScene(state, scene) {
            state.scene = scene
        },

        setPersona(state, persona) {
            state.persona = persona
        },

        setRemoteVideo(state, remoteVideo) {
            state.remoteVideo = remoteVideo
        },
    },
}