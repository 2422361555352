<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="content">
        <Carousel
          :itemsToShow="1"
          :wrapAround="true"
          :transition="500"
          v-model="currentSlide"
        >
          <Slide
            v-for="(slide, index) in this.slides"
            :key="index"
          >
            <div class="slide-content">
              <div class="image-container">
                <img :src="slide.card.imageUri" />
              </div>
            </div>
          </Slide>
          <template #addons>
            <div
              v-if="this.slides[currentSlide]"
              class="content-bottom"
            >
              <h3><span class="content-title">{{ this.slides[currentSlide].card.title.replace(/^.+\-/, '') }}</span></h3>
              <ButtonIcon
                class="map-expand-button"
                symbol="maximize-2"
                @onClick="onMapExpand"
                bgColor="var(--white)"
              />
            </div>
            <Navigation>
              <template #next>
                <ButtonIcon
                  symbol="arrow-right"
                  type="btn-white"
                  bgColor="transparent"
                />
              </template>
              <template #prev>
                <ButtonIcon
                  symbol="arrow-left"
                  type="btn-white"
                  bgColor="transparent"
                />
              </template>
            </Navigation>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import ButtonIcon from '../Shared/ButtonIcon.vue'
import 'vue3-carousel/dist/carousel.css'
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import { mapGetters } from "vuex";
import MainService from '@/services/main.service'

export default {
  name: 'GalleryIndex',
  emits: ["onExpanded"],
  components: {
    ModalLayout,
    Carousel,
    Slide,
    Navigation,
    Pagination,
    ButtonIcon
  },
  data() {
    return {
      currentSlide: 0
    }
  },
  computed: {
    ...mapGetters({
      getCommunity: "main/getCommunity",
      getLastConversation: "conversation/getLastConversation",
    }),

    community() {
      return this.getCommunity;
    },

    // Last conversation added to the array
    lastConversation() {
      return this.getLastConversation;
    },

    slides() {
      return this.lastConversation.fulfillmentMessages.filter((card) => 'card' in card);
    }

  },

  methods: {
    onMapExpand() {
      MainService.expandAppWindow();
      this.$emit('onExpanded');
    }
  }
}
</script>
<style scoped>
#modal-close.a {
  color: var(--black);
}

.wrapper {
  text-align: left;
  color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

.wrapper :deep(.main) {
  height: 100%;
}

.content {
  padding: 0;
  height: 100%;
  background-color: #3E2A62;
  border-radius: 30px;
}

.content::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 40%;
  z-index: 1;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.content :deep(.content-bottom) {
  color: var(--black);
  padding: 4% 5%;
  position: absolute;
  bottom: 85px;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content-bottom h3>span {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.28)) drop-shadow(0px 1px 4px rgba(16, 24, 40, 0.30));
}

.title {
  font-size: 18px;
}

.subtitle {
  font-size: 12px;
}

.slide-content {
  width: 100%;
}

.content :deep(.slide-content) {
  max-height: 100%;
}

.content :deep(.content-title) {
  align-self: flex-end;
}

.content :deep(.slide-content img) {
  max-height: 100%;
  border-radius: 30px;
}

.content :deep(.btn-white) {
  color: #FFFFFF;
  border-color: transparent;
  stroke-width: 3px;
  padding: 2px 5px;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.28)) drop-shadow(0px 1px 4px rgba(16, 24, 40, 0.30));
}

.slide-content,
.content :deep(.carousel),
.content :deep(.carousel__viewport),
.content :deep(.carousel__track),
.content :deep(.slide-content img) {
  height: 100%;
}

.content :deep(.carousel__viewport) {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.content :deep(.carousel__prev) {
  top: 88%;
  transform: unset;
  left: 4%;
  z-index: 100;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.28)) drop-shadow(0px 1px 4px rgba(16, 24, 40, 0.30));
}

.content :deep(.carousel__next) {
  top: 88%;
  transform: unset;
  right: 4%;
  z-index: 100;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.28)) drop-shadow(0px 1px 4px rgba(16, 24, 40, 0.30));
}

.content :deep(.carousel__pagination) {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 6%;
  left: -5%;
  z-index: 2;
}

.content :deep(.carousel__pagination-button) {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: transparent;
  z-index: 3;
}

.content :deep(.carousel__pagination-button::after) {
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: var(--vc-pgn-border-radius);
  background-color: #D7C8F1;
  border-radius: 5px;
  z-index: 3;
}

.content :deep(.carousel__pagination-button:hover::after),
.content :deep(.carousel__pagination-button--active::after) {
  background-color: var(--white);
}

.content :deep(.map-expand-button) {
  align-self: flex-end;
}

.content :deep(.map-expand-button-container) {
  margin: 20px;
  display: flex;
}

.content :deep(.btn-continue) {
  color: black;
}

.content :deep(.media-detail) {
  color: var(--tertiary-color);
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.content :deep(.icon) {
  color: var(--tertiary-color);
  margin-right: 5px;
}

.wrapper :deep(.top) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.content :deep(.btn-continue) {
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.28)) drop-shadow(0px 1px 4px rgba(16, 24, 40, 0.30));
}

.content :deep(.image-container) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; 
  overflow: hidden; 
}

.content :deep(.image-container img) {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 30px;
}
</style>