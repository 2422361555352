<template>
  <!---<button :disabled="disabled">{{ text }}</button>-->
  <a @click="onClick" :class="text ? 'TextIcon' : ''">
    {{ text }}
    <i
      :data-feather="symbol"
      :class="type ? 'btn-white' : 'btn-continue'"
      :style="backgroundColor"
    />
  </a>
</template>
<script>
import feather from 'feather-icons'
export default {
  name: 'ButtonIcon',
  props: {
    // iconCode: String,
    // iconColor: String,
    bgColor: String,
    symbol: String,
    disabled: Boolean,
    type: String,
    text: String,
  },
  computed: {
    backgroundColor() {
      let bgColor = this.bgColor ? this.bgColor : 'var(--secondary-color)'
      return 'background: ' + bgColor + ';' + 'border-radius: 24px;'
    },
  },
  mounted() {
    feather.replace()
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    },
  },
}
</script>
<style scoped>
.btn-continue {
  padding: 8px 10px;
  gap: 10px;
  width: 37px;
  height: 37px;
  position: relative;
  background: var(--secondary-color);
  border-radius: 24px;
  color: var(--white);
  flex: none;
  order: 0;
  flex-grow: 0;
}
.btn-white {
  padding: 8px 10px;
  gap: 10px;
  width: 45px;
  height: 45px;
  position: relative;
  /* Quext/Transparent */
  background: var(--black);
  border-radius: 24px;
  color: black;
  border: 2px solid var(--black);
  flex: none;
  order: 0;
  flex-grow: 0;
}
.TextIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
}
a {
  color: var(--white);
  cursor:pointer
}
</style>
