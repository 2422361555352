import UserService from './../../services/user.service'

export default {
    namespaced: true,
    state: {
        // user: {
        //     first: '',
        //     last: '',
        //     email: '',
        //     phone: '',
        //     maxBudget: '',
        //     moveInDate: '',
        //     bedrooms: ['ONE_BEDROOM'],
        //     dateISOFormat: '',
        //     timeISOFormat: '',
        //     date: '',
        //     time: '',
        //     notes: '',
        //     smsOptedIn: 20,
        //     dialogflowSession: '',
        //     latitude: '',
        //     longitude: '',
        //     platform: '',
        //     userAgent: '',
        // },
        conversationId: null,
        guestId: null
    },
    getters: {
        getGuestId(state) {
            return state.guestId
        },
        getConversationId(state) {
            return state.conversationId
        },
    },

    actions: {

        async loadGuestId({ commit }) {
            try {
                console.log('loadGuestId #1')
                const guestId = UserService.loadGuestId();
                console.log('loadGuestId #2', guestId)
                commit('setGuestId', guestId)
            } catch (error) {
                console.log(error)
            }
        },

        async saveConversation({commit}, payload) {
            try {
                console.log('saveConversation #1')
                const conversation = await UserService.saveConversation(payload);
                console.log('saveConversation #2', conversation)
                // commit('setConversationId', fulfillment.conversation_id)
                commit;
            } catch (error) {
                console.log(error)
            }
        },

        async setConversationId({ commit }, uuid) {
            commit('setConversationId', uuid)
        }

    },
    mutations: {

        setGuestId(state, guestId) {
            state.guestId = guestId;
        },
        setConversationId(state, conversationId) {
            state.conversationId = conversationId;
        },
    },
}