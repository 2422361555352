<template>
  <ModalLayout>
    <template v-slot:title>
      <span class="content-title">Select Preferences</span>
    </template>
    <template v-slot:main>
      <div class="content">
        <div class="space">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/BedFrontIcon.svg" />
            <span class="price-label">
              {{
                getTranslation(
                  'schedule_tour_form_label_desired_number_bedrooms'
                )
              }}
            </span>
          </div>
          <multi-button
            :activeButton="this.form.bedrooms"
            :label="
              getTranslation('schedule_tour_form_label_desired_number_bedrooms')
            "
            @update:activeButton="updateForm('bedrooms', $event)"
          />
        </div>
        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/DollarIcon.svg" />
            <span class="price-label">
              {{ getTranslation('schedule_tour_form_label_max_budget') }}
            </span>
          </div>
          <div class="field">
            <Slider
              v-model="pricerange.value"
              :value="this.form.maxBudget"
              :min="100"
              :max="4500"
              :step="500"
              :format="formatTooltip"
              class="slider-purple"
              @update="updateForm('maxBudget', $event)"
            />
          </div>
        </div>
        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Square1Icon.svg" />
            <span class="price-label">
              {{ getTranslation('floorplans_form_label_square_feet') }}
            </span>
          </div>
          <div class="field">
            <Slider
              v-model="feetrange.value"
              :value="this.form.maxFeet"
              :min="100"
              :max="4500"
              :step="50"
              class="slider-purple"
              @update="updateForm('maxFeet', $event)"
            />
          </div>
        </div>
      </div>
    </template>
  </ModalLayout>
  <container-buttons
    :textLeft="getTranslation('button_back')"
    :onClickLeft="() => this.onClickPrev()"
    :onClickRight="() => this.onClickNext()"
    :textRight="getTranslation('button_next')"
  />
</template>

<script scoped>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import ContainerButtons from '@/components/Shared/ContainerButtons.vue'
import MultiButton from '@/components/Shared/MultiButton.vue'
import Slider from '@vueform/slider'
import feather from 'feather-icons'
import '@vueform/slider/themes/default.css'

export default {
  name: 'ScheduleTourFormOne',
  components: {
    ModalLayout,
    ContainerButtons,
    MultiButton,
    Slider,
  },
  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
    }),
  },
  props: {
    onClickReview: Function,
    onClickPrev: Function,
    onClickNext: Function,
    form: {
      maxBudget: Object,
      maxFeet: Object,
      moveInDate: String,
      errors: Array,
      bedrooms: Array,
      bathrooms: Number,
    },
    date: String,
  },
  data() {
    return {
      moveInDate: '',
      errors: [],
      bedroomOptions: [
        { label: 'One Bedroom', value: '1' },
        { label: 'Two Bedrooms', value: '2' },
        { label: 'Three Bedrooms', value: '3' },
      ],
      optionsBudget: [
        { label: '$1.200', value: '$1.200' },
        { label: '$1.300', value: '$1.300' },
        { label: '$1.400', value: '$1.400' },
        { label: '$1.500', value: '$1.500' },
        { label: '$1.600', value: '$1.600' },
        { label: '$1.700', value: '$1.700' },
        { label: '$1.800', value: '$1.800' },
        { label: '$1.900', value: '$1.900' },
        { label: '$2.000', value: '$2.000' },
        { label: '$2.100', value: '$2.100' },
        { label: '$2.200', value: '$2.200' },
        { label: '$2.300', value: '$2.300' },
        { label: '$2.400', value: '$2.400' },
      ],
      // bathrooms: {
      //   value: 1,
      // },
      pricerange: {
        value: [100, 4500],
      },
      feetrange: {
        value: [100, 4500],
      },
    }
  },
  async mounted() {
    feather.replace()
  },
  methods: {
    formatTooltip(val) {
      return `$${Math.round(val)}`
    },
    updateForm(key, event) {
      this.$emit('update-form', key, event)
    },
  },
}
</script>

<style scoped>
.price-label {
  color: var(--tertiary-color);
  margin-inline: 6px;
  font-size: 15px;
}

.wrapper-price-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrapper-price-label-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

hr {
  border-top: 1px solid var(--secondary-color);
}
.separator {
  margin-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
}
.input-container {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
}

label {
  margin-bottom: 5px;
  left: 0px;
}

.style-icon {
  width: 20px;
  height: 20px;
  color: var(--tertiary-color);
  margin-right: 4px;
}
.square-icon {
  width: 20px;
  height: 20px;
  color: var(--tertiary-color);
  margin-right: 4px;
  transform: rotate(-45deg);
}

.space {
  margin-bottom: 20px;
}

.space-right {
  margin-right: 20px;
}

.modals-container {
  text-align: left;
  color: #ffffff;
  height: 100%;
  position: relative;
}

.left {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.button-container > div {
  width: 46%;
}

.slider {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-top: 1px solid var(--secondary-color) !important;
  border-left: 1px solid var(--secondary-color);
  border-right: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color) !important;
  cursor: pointer;
  font-size: 12px;
}

.input-label {
  position: absolute;
  top: -10px;
  /* left: 10px; */
  color: #d7c8f1;
  padding: 0 5px;
  text-decoration-color: white;
}

.field {
  padding-inline: 20px;
  padding-block: 20px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.date-input {
  padding: 20px;
  border: 1px solid var(--secondary-color) !important;
}

.slider-purple {
  --slider-connect-bg: var(--secondary-color);
  --slider-tooltip-bg: var(--secondary-color);
  --slider-handle-ring-color: #3b82f630;
}

.title {
  font-size: 25px;
}
</style>
