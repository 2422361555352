<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="content">
        <check-icon />
        <label class="title">{{ getTranslation('schedule_tour_review_title_thankyou') }}</label>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import CheckIcon from '@/components/Shared/CheckIcon.vue'

export default {
  name: 'ScheduleTourThanks',

  components: {
    ModalLayout,
    CheckIcon
  },

  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
    }),
  },
}
</script>

<style scoped>
.content {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-top: 64px;
}

</style>
