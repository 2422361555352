<template>
    <input
      :id="inputId"
      :type="type"
      :placeholder="`${placeholder}${required ? '*' : ''}`"
      :class="`
        input-field-square 
        ${borderTop && 'border-top'} 
        ${borderBottom && 'border-bottom'} 
        space
        `
      "
      :style="{
        borderBottom: error
          ? '1px solid #ED441B !important'
          : 'transparent',
      }"
      :value="value"
      @input="onInput($event)"
      :maxlength="max"
    />
    <label
        class="required space"
        v-if="error"
        :style="{ color: error ? 'var(--error)' : 'var(--tertiary-color)'}"
      >
        {{ error ? messageError : getTranslation('schedule_tour_required') }}
    </label>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      id: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      borderTop: {
        type: Boolean,
        default: false,
      },
      borderBottom: {
        type: Boolean,
        default: false,
      },
      max: {
        type: Number,
      },
      value: {
        type: String,
        default: '',
      },
      messageError: {
        type: String,
        default: 'this field is required',
      },
    },
    computed: {
      ...mapGetters({
        getTranslation: 'translation/getTranslation',
      }),
      inputId() {
        return this.id || `input-${this._uid}`
      },
    },
    methods: {
      onInput(event) {
        this.$emit('update:value', event.target.value)
      },
    },
  }
  </script>
  <style scoped>
  
  .input-field-square {
    line-height: 24px;
    border: 1px solid var(--primary-color) !important;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--primary-color);
    color: var(--white);
    color-scheme: var(--primary-color);
  }
  
  .input-field-square::placeholder {
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    color: var(--grey);
  }
  
  .input-field-square:active {
    outline: none;
    outline-color: transparent;
    outline-style: none;
  }
  
  .input-field-square:focus {
    outline: none;
    outline-color: transparent;
    outline-style: none;
  }
  
  .border-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .border-bottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .space {
    margin-bottom: 2px;
  }
  </style>