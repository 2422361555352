<!-- Loads initial values -->
<template>
  <div>
    <XyzTransition xyz="fade">
      <Loader v-if="loaderIsShowing" :message="loaderIsShowing" />
    </XyzTransition>
    <!-- <XyzTransition xyz="fade duration-0 fade duration-2 appear-stagger" > 
        <Intro v-if="introIsShowing" />
    </XyzTransition> -->
    <router-view v-if="isInitialized" :key="$route.path"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Shared/Loader.vue";
// import Intro from "@/components/Shared/Intro.vue";
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "app",
  components: {
    Loader,
    // Intro
  },

  data() {
    return {
      isInitialized: false,
    };
  },

  methods: {
    ...mapActions({
      loadTranslations: "translation/loadTranslations",
      loadCommunity: "main/loadCommunity",
      loadPersonas: "main/loadPersonas",
      setLoaderIsShowing: "main/setLoaderIsShowing",
      // setIntroIsShowing: "main/setIntroIsShowing",
      setLoaderMessage: "main/setLoaderMessage",
      saveConversation: "user/saveConversation",
      loadGuestId: "user/loadGuestId",
      setConversationId: "user/setConversationId",
    }),
  },

  computed: {
    ...mapGetters({
      getLoaderIsShowing: "main/getLoaderIsShowing",
      // getIntroIsShowing: "main/getIntroIsShowing",
      getCommunity: "main/getCommunity",
      getConversationId: "user/getConversationId",
      getGuestId: "user/getGuestId",
    }),

    loaderIsShowing() {
      return this.getLoaderIsShowing;
    },

    community() {
      return this.getCommunity;
    },

    conversationId(){
      return this.getConversationId;
    },

    guestId(){
      return this.getGuestId;
    }

    // introIsShowing(){
    //   return this.getIntroIsShowing;
    // }
  },

  async mounted() {
    console.log("APP::mount ==>");
    console.log(window.innerWidth)
  },

  async created() {
    // Gets instance of URLSearchParams
    let urlParams = new URLSearchParams(window.location.search);

    // Gets the community form the url query
    // const community = urlParams.has("community") ? urlParams.get("community") : "73b6c7c3-ece9-4760-8b32-01c2c16cac1c";
    const communityResult = urlParams.has("community")
      ? urlParams.get("community")
      : "";

    if (!communityResult) {
      alert("Community not found. Please specify a community.");
      return;
    }

    // this.setLoaderMessage("Loading");
    this.setLoaderIsShowing("Loading configuration");
    await this.loadTranslations();
    await this.loadCommunity(communityResult);
    await this.loadPersonas;

    // Saves the conversation id that will be used in several 
    // API calls
    await this.setConversationId(uuidv4());

    // Loads the guest id stored in the local storage
    this.loadGuestId();

    const payload = {
      community_id: this.community.id_community,
      conversation_id: this.conversationId,
      customer_id: this.community.customer_id,
      browser_platform: window.navigator.userAgent,
      digital_human_name: "Mia",
      language: "en",
      communication_method: "web",
      input_type: "typed",
      user: {
          "guest_id": this.guestId,
          "request": "Hi",
          "client_time": new Date().toLocaleTimeString(),
          "location": {
              "latitude": "9.9044373",
              "longitude": "-84.0842156"
          }
      }
    };

    await this.saveConversation(payload);

    // this.setIntroIsShowing(true);
    this.setLoaderIsShowing(false);
    this.isInitialized = true;
  },
};
</script>

<style>
body{
  overflow-y: hidden;
  overflow-x: hidden;
}
/* html :deep(body) {
  overflow-y: hidden;
  color: yellow;
} */
</style>