<template>
  <div class="wrapper">
    <div class="title">
      Contact
    </div>
    <div class="subtitle">
      Subtitle
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactFormIndex',
}
</script>
<style scoped>
  .wrapper{
    text-align: left;
    padding: 20px;
    color: var(--white);
  }
  .title{
    font-size: 18px;
  }
  .subtitle{
    font-size: 12px;
  }
</style>