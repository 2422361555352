import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

class UserService {
  async saveConversation(payload) {
    console.log('UserService::saveConversation', payload)

    return axios.post(
      `${process.env.VUE_APP_SERVER_ENDPOINT}/fulfillment/response`,
      payload
    )
  }

  loadGuestId() {
    let guestId = localStorage.getItem('guest_id')

        if (guestId === null) {
            localStorage.setItem("guest_id", uuidv4());
            guestId = localStorage.getItem("guest_id");
        }
        
        return guestId;
    }

    async saveFeedbackRating(payload) {
        console.log("UserService::saveFeedbackRating", payload);
        return axios.put(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/conversation/feedback`,
            payload)
        }

      async saveFeedbackComment(payload) {
        console.log('UserService::saveFeedbackComment', payload)
    
        return axios.put(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/conversation/feedback-comment`,
          payload)
        }
}

export default new UserService()
