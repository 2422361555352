import axios from 'axios'

class ConversationService {
  // update guest data
  async updateGuest(payload) {
    return await axios.post(
      `${process.env.VUE_APP_SERVER_ENDPOINT}/conversation/guest`,
      payload
    )
  }
}

export default new ConversationService()
