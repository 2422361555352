<template>        
    <div class="button-container" :style="`justify-content: ${textCenter ? 'center': 'space-evenly'};`">
      <div class="left" v-if="textLeft">
        <Button
          :text="textLeft"
          style="z-index: 3; background-color: var(--primary-color); color: var(--secondary-color)"
          @click="() => onClickLeft()" 
        />
      </div>
      <div class="right" v-if="textRight">
        <Button         
          :text="textRight" 
          @click="() => onClickRight()" 
        />
      </div>
      <div v-if="textCenter">
        <Button
          :text="textCenter"
          style="z-index: 3; background-color: var(--primary-color); color: var(--secondary-color)"
          @click="() => onClickCenter()"
        />
      </div>
    </div>
  </template>
  
  <script scoped>
  import Button from '@/components/Shared/Button.vue'
  
  export default {
    name: 'ContainerButtons',
    components: {
      Button,
    },
    props: {
      textRight: String,
      onClickRight: Function,
      onClickLeft: Function,
      onClickCenter: Function,
      textLeft: String,
      textCenter: String,
      onClickNext: Function
    },
  }
  </script>
  
  <style scoped>
  .button-container {
    background-color: var(--primary-color) !important;
    display: flex;
    position: absolute;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    bottom: 0px;
    border-top: 1px solid var(--secondary-color);
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }
  
  .button-container > div{
    width: 40%
  }
  </style>