<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="content">
        <div class="bed-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/BedFrontIcon.svg" />
            <span class="price-label"> Bedrooms </span>
          </div>
          <multi-button
            :activeButton="this.form.bedrooms"
            @update:activeButton="updateForm('bedrooms', $event)"
            :options="this.bedroomsArray"
          />
        </div>
        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/DollarIcon.svg" />
            <span class="price-label">
              {{ getTranslation("schedule_tour_form_label_max_budget") }}
            </span>
          </div>
          <div class="field">
            <Slider
              v-model="pricerange.value"
              :value="this.form.maxBudget"
              :min="100"
              :max="4500"
              :step="500"
              :format="formatTooltip"
              class="slider-purple"
              @update="updateForm('maxBudget', $event)"
            />
          </div>
        </div>
        <div class="input-container">
          <div class="wrapper-price-label space">
            <img src="@/assets/Icons/Square1Icon.svg" />
            <span class="price-label">
              {{ getTranslation("floorplans_form_label_square_feet") }}
            </span>
          </div>
          <div class="field">
            <Slider
              v-model="feetrange.value"
              :value="this.form.maxFeet"
              :min="100"
              :max="4500"
              :step="50"
              class="slider-purple"
              @update="updateForm('maxFeet', $event)"
            />
          </div>
        </div>
      </div>
    </template>
  </ModalLayout>
  <!-- <container-buttons
    :textLeft="getTranslation('button_back')"
    :onClickLeft="() => this.updateStep(1)"
    :onClickRight="submit"
    :textRight="getTranslation('button_next')"
  /> -->
  <div class="button-container">
    <div class="left">
      <Button
        :text="getTranslation('button_previous')"
        bgColor="var(--primary-color)"
        @click="this.onClickPrev"
        style="color: #d7c8f1"
      />
    </div>
    <div class="right">
      <Button
        :text="getTranslation('button_next')"
        @click="
          submitClicked();
          onClickNext();
          sendFilterData();
        "
        style="color: #f4edff"
      />
    </div>
  </div>
</template>

<script scoped>
import { mapGetters } from "vuex";
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import Slider from "@vueform/slider";
import feather from "feather-icons";
import "@vueform/slider/themes/default.css";
import ConversionService from "../../services/conversion.service";
import { v4 as uuidv4 } from "uuid";
import Button from "../Shared/Button.vue";
import MultiButton from "../Shared/MultiButton.vue";
import AvailabilityService from "@/services/availability.service";
import convertNumberToWord from "@/utils/convertNumberToWord";

export default {
  name: "FloorplansFormTwo",
  components: {
    ModalLayout,
    Slider,
    MultiButton,
    Button,
  },

  computed: {
    ...mapGetters({
      getTranslation: "translation/getTranslation",
      getCommunity: "main/getCommunity",
    }),
    community() {
      return this.getCommunity;
    },
  },

  watch: {
    "form.bedrooms": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.onBedroomChange(newVal, oldVal);
        }
      },
      deep: true,
    },
  },

  props: {
    onClickReview: Function,
    onClickPrev: Function,
    onClickNext: Function,
    onClickLeft: Function,
    onClickRight: Function,
    form: {
      maxBudget: Object,
      maxFeet: Object,
      moveInDate: String,
      errors: Array,
      bedrooms: Array,
      bathrooms: Number,
    },
    date: String,
    bedsArray: Array,
    priceRange: Array,
    sizeRange: Array,
    pricerangeProp: Array,
    feetrangeProp: Array,
    floorplans: Array,
  },
  data() {
    return {
      currentStep: 2,
      availability: null,
      bedroomOptions: [],
      priceMin: 100,
      priceMax: 3000,
      sizeMin: 50,
      sizeMax: 2000,
      pricerange: this.pricerangeProp || [100, 3000],
      feetrange: this.feetrangeProp || [50, 2000],
      bedroomsArray: [],
    };
  },

  async mounted() {
    feather.replace();

    for (const key of this.floorplans) {
      this.bedroomsArray.push(key.beds);
    }
    try {
      const communityId = this.community.id_community;
      const availableBedrooms = await AvailabilityService.getUnitRanges(
        communityId
      );
      console.log("Data is", availableBedrooms);
      this.availability = availableBedrooms;
    } catch (error) {
      console.error("Error fetching initial unit ranges:", error);
    }
    this.getPrefilteringData();
  },
  methods: {
    formatTooltip(val) {
      return `$${Math.round(val)}`;
    },
    updateForm(key, event) {
      this.$emit("update-form", key, event);
      if (key === "maxBudget") {
        this.$emit("update-pricerange", this.pricerange);
      }
      if (key === "maxFeet") {
        this.$emit("update-feetrange", this.feetrange);
      }
    },

    onBedroomChange(newVal) {
      if (newVal == "" || newVal == undefined) {
        newVal = this.bedsArray;
      }
      this.getPrefilteringData(newVal);
    },
    updateStep(step) {
      this.currentStep = step;
    },

    sendFilterData() {
      const filter = {
        bedrooms: this.form.bedrooms,
        minBudget: this.form.maxBudget,
        maxSquareFeet: this.form.maxFeet,
      };
      console.log("Filtered results", filter);
      this.$emit("filter-submitted", filter);
    },
    async submitClicked() {
      console.log("submitClicked ==>", this.form);

      // add form data to guest table
      const guestPayload = {
        id_guest: uuidv4(), // missing value
        first_name: this.form.name,
        last_name: this.form.lastname,
        phone_number: this.form.phone,
        email: this.form.email,
        notes: this.form.notes,
      };
      let bedrooms;

      if (this.form.bedrooms && this.form.bedrooms.length) {
        bedrooms = Array.from(this.form.bedrooms).map((v) =>
          this.getValueBr(v)
        );
      } else {
        bedrooms = ["ONE_BEDROOM"];
      }

      const guestCardPayload = {
        prospect: {
          first_name: this.form.name,
          last_name: this.form.lastname,
          phone: this.form.phone,
          email: this.form.email,
        },
        preferred: {
          move_in_start_date: this.form.moveInDate,
          no_of_bedrooms: bedrooms,
          // uses correct value here
          amount: this.form.maxBudget,
        },
        comment: this.form.notes,
        tour_included: false,
        source: "dh",
        community_id: this.community.id_community,
        customer: this.community.customer_id,
      };
      console.log("Payload guestcard is ->", guestPayload);
      console.log("Payload guestcard is ->", guestCardPayload);
      const payload = {
        guest: guestPayload,
        guest_card: guestCardPayload,
      };
      ConversionService.updateGuest(payload);
    },

    getValueBr(value) {
      let numberToWord = convertNumberToWord(+value);
      const bedroomCount = (numberToWord += "_BEDROOM");
      return bedroomCount;
    },

    clearFilters() {
      this.localFloorplans = this.floorplans.slice();
      this.getPrefilteringData(this.form.bedrooms);
      this.filter = {};
      this.sendFilterData();
    },

    async getPrefilteringData(newVal) {
      try {
        const bedrooms = !this.form.bedrooms ? this.bedsArray : newVal;
        let priceMins = [],
          priceMaxs = [],
          sizeMins = [],
          sizeMaxs = [];

        for (const bedroom_count of bedrooms) {
          if (this.availability && this.availability[bedroom_count]) {
            priceMins.push(
              Number(this.availability[bedroom_count]?.priceRange?.floor || 0)
            );
            priceMaxs.push(
              Number(this.availability[bedroom_count]?.priceRange?.ceiling || 0)
            );
            sizeMins.push(
              this.availability[bedroom_count]?.squareFeetRange?.floor || 0
            );
            sizeMaxs.push(
              this.availability[bedroom_count]?.squareFeetRange?.ceiling || 0
            );
          }
        }
        [this.priceMin, this.priceMax] = [
          Math.min(...priceMins),
          Math.max(...priceMaxs),
        ];
        [this.sizeMin, this.sizeMax] = [
          Math.min(...sizeMins),
          Math.max(...sizeMaxs),
        ];

        if (this.priceMin == this.priceMax) {
          this.priceMax = this.priceMin + 150;
        }
        if (this.sizeMin == this.sizeMax) {
          this.sizeMax = this.sizeMin + 150;
        }
        this.pricerange = [this.priceMin, this.priceMax];
        this.feetrange = [this.sizeMin, this.sizeMax];
      } catch (error) {
        console.error("Error determining slider ranges:", error);
      }
    },
  },
};
</script>

<style scoped>
.price-label {
  color: var(--tertiary-color);
  margin-inline: 6px;
  font-size: 15px;
}

.wrapper-price-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrapper-price-label-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

hr {
  border-top: 1px solid var(--secondary-color);
}

.separator {
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  padding-inline: 26px;
}

.bed-container {
  margin-bottom: 40px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
}

label {
  margin-bottom: 5px;
  left: 0px;
}

.style-icon {
  width: 20px;
  height: 20px;
  color: var(--tertiary-color);
  margin-right: 4px;
}

.square-icon {
  width: 20px;
  height: 20px;
  color: var(--tertiary-color);
  margin-right: 4px;
  transform: rotate(-45deg);
}

.space {
  /* margin-top: 36px; */
  margin-bottom: 20px;
  margin-left: 10px;
}

.space-right {
  margin-right: 20px;
}

.modals-container {
  text-align: left;
  color: #ffffff;
  height: 100%;
  position: relative;
}

.button-container {
  height: 10%;
  display: flex;
  background-color: var(--primary-color);
  border-radius: 0px 0px 28px 28px;
  background-color: var(--primary-color) !important;
  display: flex;
  position: absolute;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  bottom: 0px;
  border-top: 1px solid var(--secondary-color);
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  justify-content: space-evenly;
}

.left {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.button-container > div {
  width: 46%;
}

.slider {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-top: 1px solid var(--secondary-color) !important;
  border-left: 1px solid var(--secondary-color);
  border-right: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color) !important;
  cursor: pointer;
  font-size: 12px;
}

.input-label {
  position: absolute;
  /* top: -10px; */
  /* left: 10px; */
  color: #d7c8f1;
  padding: 0 5px;
  text-decoration-color: white;
}

.field {
  padding-inline: 36px;
  padding-block: 20px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.date-input {
  padding: 20px;
  border: 1px solid var(--secondary-color) !important;
}

.slider-purple {
  --slider-connect-bg: var(--secondary-color);
  --slider-tooltip-bg: var(--secondary-color);
  --slider-handle-ring-color: #3b82f630;
}

.title {
  font-size: 25px;
}
</style>
