<template>
  <ModalLayout>
    <template v-slot:title>
      <div v-if="time" style="font-size: 28px">
        {{ newTime.value }}
      </div>
      <div v-else style="font-size: 28px">
        {{ getTranslation('time_top_right_tour') }}
      </div>
      <hr style="border-top: 1px solid var(--secondary-color)" />
    </template>
    <template v-slot:main>
      <div class="content content-schedule">
        <div class="loader" v-if="this.loading">
          <basic-loader></basic-loader>
        </div>
        <p v-if="this.emptyResponse">{{ getTranslation('time_not_found') }}</p>
        <ToggleButtonsInput
          :value="newTime.value"
          :options="clockOptions"
          @change="(val) => handleChange(val)"
          size="large"
          class="ml-2"
          buttonClass="px-3"
          v-if="!this.loading && !this.emptyResponse"
        />
      </div>
    </template>
  </ModalLayout>
</template>
<script>
import ToggleButtonsInput from '../Shared/ToggleButtonsInput.vue'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import BasicLoader from '@/components/Shared/BasicLoader.vue'
import CalendarService from '@/services/calendar.service'
import feather from 'feather-icons'
import moment from 'moment'

import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleTourTimeController',
  components: {
    ToggleButtonsInput,
    ModalLayout,
    BasicLoader,
  },
  computed: {
    ...mapGetters({
      getCommunity: 'main/getCommunity',
      getTranslation: 'translation/getTranslation',
    }),
    newTime() {
      return { value: this.time, key: this.time }
    },
    community() {
      return this.getCommunity
    },
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    time: String,
  },
  data() {
    return {
      loading: false,
      emptyResponse: false,
      clockOptions: [],
    }
  },
  mounted() {
    feather.replace()
    const payloadDate = moment(this.date, 'M/D/YYYY').format('YYYY-MM-DD')
    this.getAvailability(payloadDate)
  },
  methods: {
    async getAvailability(date) {
      this.loading = true
      const payload = {
        timeData: {
          fromDate: date,
          toDate: date,
        },
        platformData: {
          customerUUID:
            this.community.customer_id ||
            '8e2c4907-0be1-44a9-887b-f9d82e5b0440',
          communityUUID:
            this.community.id_community ||
            'eca6948e-3aa6-4398-906d-a2cdf982371e',
        },
      }
      const output = await CalendarService.checkAvailableTimes(payload)
      this.loading = false
      if (output?.length > 0) {
        this.clockOptions = this.parseAvailableTimes(output)
      }
    },
    parseAvailableTimes(data) {
      const availableTimes = []
      this.emptyResponse = false
      for (let i = 0; i < data?.length; i++) {
        const timeStr = data[i].split(' ')[1]
        const time = moment(timeStr, 'HH:mm:ss').format('h:mm A')
        availableTimes.push({ key: time, value: time })
      }
      if(availableTimes.length === 0) {
        this.emptyResponse = true
      }
      return availableTimes
    },
    handleChange(val) {
      this.$emit('timeSelected', val)
    },
  },
}
</script>

<style scoped>
.loader {
  margin-top: 25%;
  margin-left: 35%;
}
.content {
  padding: 0 10px;
  height: 33em;
  overflow-y: scroll;
}

hr {
  border-top: 1px solid var(--secondary-color);
}

@media only screen and (max-height: 700px) {
  .content-schedule {
    height: 30em;
  }
}
</style>
