<template>
  <div class="modals-container">
    <!-- Date -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 0">
        <ScheduleDate
          @dateSelected="setMoveInDate"
          :value="this.moveInDate"
          title="When do you want to move in?"
        />
        <container-buttons
          :textLeft="getTranslation('button_cancel')"
          :onClickLeft="() => this.closeModal()"
          :onClickRight="() => this.updateStep(1)"
          :textRight="getTranslation('button_next')"
        />
      </div>
    </XyzTransition>

    <!-- Basic info Form One-->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 1">
        <FloorplansFormOne
          :onClickPrev="() => this.updateStep(0)"
          :onClickNext="() => this.updateStep(2)"
          :onClickSkip="() => this.updateStep(2)"
          :form="this.form"
          @update-form="updateForm"
        />
      </div>
    </XyzTransition>

    <!-- Basic info Form Two -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 2">
        <FloorplansFormTwo
          :form="{ ...this.form, moveInDate: this.moveInDate }"
          :floorplans="floorplansAvailability"
          :onClickPrev="() => (this.currentStep = 1)"
          :onClickNext="() => (this.currentStep = 3)"
          @update-form="updateForm"
        />
      </div>
    </XyzTransition>

    <!-- FloorplanList -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 3">
        <FloorplansList
          v-if="floorplansAvailability"
          :floorplans="floorplansAvailability"
          @onFloorplanClick="onFloorplanClick"
        />
        <container-buttons
          :textCenter="getTranslation('button_done')"
          :onClickCenter="() => this.closeModal()"
        />
      </div>
    </XyzTransition>

    <!-- FloorplansDetail -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 4 && this.selectedFloorplan != null">
        <FloorplansDetail
          @onScheduleClick="onScheduleClick"
          :floorplan="this.selectedFloorplan"
        />
        <container-buttons
          :textLeft="getTranslation('button_back')"
          :onClickLeft="() => this.updateStep(3)"
        />
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AvailabilityService from "@/services/availability.service";
import FloorplansList from "@/components/Floorplans/FloorplansList.vue";
import FloorplansDetail from "@/components/Floorplans/FloorplansDetail.vue";
import FloorplansFormOne from "@/components/Floorplans/FloorplansFormOne.vue";
import FloorplansFormTwo from "@/components/Floorplans/FloorplansFormTwo.vue";
import ScheduleDate from "@/components/Floorplans/ScheduleDate.vue";
import ContainerButtons from "@/components/Shared/ContainerButtons.vue";

export default {
  name: "FloorplansIndex",

  components: {
    FloorplansList,
    FloorplansDetail,
    ScheduleDate,
    FloorplansFormOne,
    FloorplansFormTwo,
    ContainerButtons,
  },

  data() {
    return {
      currentStep: 0,
      selectedFloorplan: null,
      availability: null,
      date: null,
      form: {
        name: "",
        lastname: "",
        email: "",
        phone: "",
      },
      moveInDate: null,
    };
  },

  computed: {
    ...mapGetters({
      getCommunity: "main/getCommunity",
      getPersona: "sdk/getPersona",
      getTranslation: "translation/getTranslation",
    }),

    community() {
      return this.getCommunity;
    },

    floorplansAvailability() {
      return this.availability;
    },

    persona() {
      return this.getPersona;
    },
  },

  async mounted() {
    const payload = {
      customerUUID: this.community.customer_id,
      communityUUID: this.community.id_community,
    };
    this.availability = await AvailabilityService.checkAvailability(payload);
  },

  methods: {
    ...mapActions({
      setModal: "main/setModal",
    }),
    updateForm(key, value) {
      this.form[key] = value;
    },
    updateStep(step) {
      this.currentStep = step;
    },
    setMoveInDate(date) {
      this.moveInDate = date.userText;
    },
    async closeModal() {
      this.setModal(null);
    },

    async onScheduleClick() {
      console.log("== Schedule ==");
      if (this.persona) {
        this.persona
          .conversationSend("Schedule A Tour")
          .then()
          .catch((error) => console.log("Request Error", error));
      }
    },

    async onFloorplanClick(floorplan) {
      console.log("onFloorplanClick x", floorplan);

      this.selectedFloorplan = floorplan;
      this.currentStep = 4;
    },
  },
};
</script>
<style scoped>
@media (max-width: 379px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 14%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}
@media (min-width: 380px) and (max-width: 400px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 20%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}

@media (min-width: 769px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 20%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}

.button-container {
  width: 100%;
  height: 10%;
  display: flex;
  background-color: var(--primary-color);
}
.left {
  display: flex;
}
.right {
  display: flex;
}

.button-container > div {
  width: 46%;
}

.begin-btn {
  font-size: 15px;
  align-self: center;
}
</style>
