<template>
  <div class="check-container">
    <i data-feather="check" color="#fff" class="check-icon"></i>
  </div>
</template>

<script>
import feather from 'feather-icons'

export default {
  name: 'CheckIcon',
  mounted() {
    feather.replace()
  },
}
</script>

<style scoped>
  .check-container {
    width: 116.67px;
    height: 116.67px;
    background-color: var(--lavender-purple-color);
    border-radius: 9999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .check-icon {
    width: 55px;
    height: 55px;
  }
</style>
