<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="feedbackComponent">
        <div class="feedback-content">
          <label class="subtitle">{{
            getTranslation('schedule_tour_review_title_appointment_review')
          }}</label>
          <div>
            <span
              class="down-icon"
              :class="{ active: downclicked }"
              @click="handleFeedback('-1')"
            >
              <i data-feather="thumbs-down"></i>
            </span>
            <span
              class="up-icon"
              :class="{ active: upclicked }"
              @click="handleFeedback('1')"
            >
              <i data-feather="thumbs-up"></i>
            </span>
          </div>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import feather from 'feather-icons'
import UserService from '@/services/user.service'

export default {
  name: 'ScheduleTourRate',

  components: {
    ModalLayout
  },

  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
      getConversationId: "user/getConversationId",
    }),

    conversationId(){
      console.log("this.getConversationId")
      return this.getConversationId;
    },
  },

  props: {
    onClickNext: Function,
  },

  data() {
    return {
      selected: '',
      upclicked: false,
      downclicked: false,
      hideText: false,
    }
  },

  mounted() {
    feather.replace()
  },

  methods: {
    updateStep(step) {
      this.currentStep = step
    },
    async handleFeedback(feedback) {
      console.log("Entering the function")
      this.selected = feedback

      if (feedback === '1') {
      this.upclicked = true;
      this.downclicked = false;
    } else if (feedback === '-1') {
      this.downclicked = true;
      this.upclicked = false;
    } else {
      this.upclicked = false;
      this.downclicked = false;
    }
      this.$emit('rate-clicked', true);
      const payload = {
        conversation_id: this.conversationId,
        feedback: this.selected
      };
    await UserService.saveFeedbackRating(payload);
    }
  },
}
</script>

<style scoped>
.feedback-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}
.feedbackComponent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: var(--white);
  margin-top: 20px;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tertiary-color);
  margin-top: 20px;
}

.bottom {
  margin-top: 20px;
  margin-left: 20px;
}

.feather {
  stroke-width: 1px;
  width: 60px;
  height: 60px;
  margin: 14px;
}

.up-icon:hover svg,
.up-icon.active svg {
  fill: var(--secondary-color);
  transform: scale(1.2);
  animation: pulse 2s;
}

.down-icon:hover svg,
.down-icon.active svg {
  fill: var(--secondary-color);
  transform: scale(1.2);
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
