import MainService from './../../services/main.service'

export default {
    namespaced: true,
    state: {
        personas: [{
                id: 1,
                name: 'Mia',
                prodUrl: 'wss://madera-video.soulmachines.cloud/',
                devUrl: 'wss://madera-video-dev.soulmachines.cloud/',
                spanishDevUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                spanishProdUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                imageIntro: require('@/assets/intro/mia.png'),
            },
            {
                id: 2,
                name: 'Jordan',
                prodUrl: 'wss://madera-jd-vid.soulmachines.cloud/',
                devUrl: 'wss://madera-jd-vid-dev.soulmachines.cloud/',
                spanishDevUrl: 'wss://ddna-madera-jordanspanishdev.soului.dh.soulmachines.cloud/',
                spanishProdUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                imageIntro: require('@/assets/intro/jordan.png'),
            },
            {
                id: 3,
                name: 'Nico',
                prodUrl: 'wss://dh.soulmachines.cloud',
                devUrl: 'wss://dh.soulmachines.cloud',
                spanishDevUrl: 'wss://dh.soulmachines.cloud',
                spanishProdUrl: 'wss://dh.soulmachines.cloud',
                imageIntro: require('@/assets/intro/nico.png'),
            },
        ],
        currentPersona: 'Mia',
        community: null,
        loaderIsShowing: false,
        introIsShowing: false,
        loaderMessage: "",
        modal: null
    },
    getters: {
        getPersonas(state) {
            return state.personas
        },
        // Is necessary to pass the key of the persona?
        getCurrentPersona(state) {
            return state.currentPersona
        },
        getCommunity(state) {
            return state.community
        },
        getLoaderIsShowing(state) {
            return state.loaderIsShowing
        },
        getIntroIsShowing(state) {
            return state.introIsShowing
        },
        getLoaderMessage(state) {
            return state.loaderMessage
        },
        getModal(state) {
            return state.modal
        },
    },
    actions: {
        async setCurrentPersona({ commit }, persona) {
            commit('setCurrentPersona', persona)
        },
        async loadPersonas({ commit }) {
            commit('loadPersonas')
        },
        async loadCommunity({ commit }, communityId) {
            try {
                console.log('communityId', communityId)
                const community = await MainService.loadCommunity(communityId)
                console.log('community', community)
                commit('loadCommunity', community.data)
            } catch (error) {
                console.log(error)
            }
        },
        async setLoaderIsShowing({ commit }, message) {
            commit('setLoaderIsShowing', message);
        },
        async setIntroIsShowing({ commit }, flag) {
            commit('setIntroIsShowing', flag);
        },
        async setLoaderMessage({ commit }, message) {
            commit('setLoaderMessage', message)
        },
        async setModal({ commit }, modal) {
            commit('setModal', modal)
        },
    },
    mutations: {
        setCurrentPersona(state, persona) {
            state.currentPersona = persona
        },
        loadCommunity(state, community) {
            state.community = community
        },
        setPersonas(state, personas) {
            state.personas = personas
        },
        setLoaderIsShowing(state, flag) {
            state.loaderIsShowing = flag
        },
        setIntroIsShowing(state, flag) {
            state.introIsShowing = flag
        },
        setLoaderMessage(state, message) {
            state.loaderMessage = message
        },
        setModal(state, modal) {
            state.modal = modal
        },
    },
}