<template>
  <div id="loader">
    <div v-if="message" id="loader-content">
      <!-- {{ this.message }} -->
      <Intro  />

    </div>
  </div>
</template>

<script>

// import { mapGetters } from "vuex";
import Intro from "@/components/Shared/Intro.vue";

export default {
  name: 'LoaderComponent',
  props: {
    message: String
  },
  components: {

  Intro
  },
  data() {
    return {}
  },

  computed: {
    // ...mapGetters({
    //   getLoaderMessage: "main/getLoaderMessage",
    // }),

    // loaderMessage(){
    //   return this.getLoaderMessage;
    // }
  },
  methods: {

  },
}
</script>

<style scoped>
#loader{
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  /* background-color: rgba(51, 49, 54, 0.904); */
  background-color: #d7c8f1;
  z-index: 1000;
  display:flex;
  justify-content: center;
  align-items: center;
}

#loader #loader-content{
  max-width: 300px;
  margin: auto;
  color: #8a5ed5;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
</style>
