import axios from 'axios'

class CalendarService {

    async checkAvailableTimes(payload) {
        return await axios
            .post(`${process.env.VUE_APP_SERVER_ENDPOINT}/tour-scheduling/availability`, payload,{
              })
            .then(
                (response) => {
                    console.log("== checkAvailabileTimes response ==", response);
                    return response.data;
                },
                (error) => {
                    console.log("== checkAvailability error ==", error);
                }
            )
    }
}

export default new CalendarService()
