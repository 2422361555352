import { createWebHistory, createRouter } from 'vue-router';
import DigitalHumanComponent from '@/views/DigitalHuman/index.vue';
import OnboardingComponent from '@/views/Onboarding/index.vue';

const routes = [{
        path: '/',
        name: 'Onboarding',
        component: OnboardingComponent,
    },
    {
        path: '/digitalhuman',
        name: 'DigitalHuman',
        component: DigitalHumanComponent,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;