<template>
  <ModalLayout>
    <template v-slot:title>
      <div v-if="!date && title" style="font-size: 24px" class="space">
        {{ title }}
      </div>
    </template>
    <template v-slot:main>
      <div class="content content-schedule">
        <VueDatePicker
          v-model="moveInDate"
          @update:model-value="setMoveInDate"
          inline
          auto-apply
          hide-offset-dates
          :dark="true"
          :enable-time-picker="false"
          :locale="this.getLanguageCode"
          :week-start="0"
          :disabled-week-days="[0]"
          :disabled-dates="disabledDates"
          month-name-format="long"
          :month-change-on-scroll="false"
        />
      </div>
    </template>
  </ModalLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  name: 'ScheduleDate',
  components: {
    ModalLayout,
    VueDatePicker,
  },
  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
      getLanguageCode: 'translation/getLanguageCode',
    }),
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
    title: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      moveInDate: this.value
    }
  },
  watch: {
    value(newValue) {
      this.moveInDate = newValue
    },
  },
  methods: {
    setMoveInDate(value) {
      this.moveInDate = value
      var day = this.moveInDate.getDate()
      var month = this.moveInDate.getMonth() + 1
      var year = this.moveInDate.getFullYear()
      var datePicked = month + '/' + day + '/' + year
      const userRequest = { userText: datePicked, inputType: 'clicked' }
      this.$emit('update:moveInDate', userRequest.userText)
      this.$emit('dateSelected', userRequest)
    },
    disabledDates(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date.getTime() < today.getTime()
    }
  },
}
</script>
<style>
.content {
  display: flex;
  flex-direction: column;
}
.content-title {
  font-size: 28px;
}
.content-schedule {
  height: 27em;
}
hr {
  border-top: 1px solid var(--secondary-color);
}
.space {
  margin-bottom: 30px;
}
.dp__theme_dark {
  --dp-background-color: transparent;
  --dp-menu-border-color: transparent !important;
  --dp-border-color: transparent !important;
  --dp-primary-text-color: var(--black);
  --dp-disabled-color-text: var(--grey);
}
.dp__cell_inner {
  border-radius: var(--dp-border-radius, 21px);
  padding: 20px;
  font-size: 15px;
}
.dp__calendar_header_item {
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 3px;
}
.dp__overlay_cell,
.dp__overlay_cell_active,
.dp__month_year_select,
.dp__calendar_header_item {
  text-transform: capitalize;
}
.dp__menu {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
.dp__month_year_row {
  padding-bottom: 2em;
  padding-top: 1em;
}
.dp__active_date {
  background: var(--tertiary-color);
}
.dp__today {
  border: transparent;
}
.dp__overlay_cell_active {
  cursor: pointer;
  border-radius: var(--dp-border-radius, 4px);
  text-align: center;
  background: var(--tertiary-color);
  color: var(--black);
}
.dp__outer_menu_wrap {
  height: 50px;
}
.dp__overlay_row {
  background-color: var(--shadow-purple-color);
}
.dp__button svg {
  color: transparent;
}
</style>