export var MapFilters = [{
        id: "school",
        categories: [],
        title: "Schools",
    },
    {
        id: "church",
        categories: [
            { name: "church", title: "Church", default: true },
            { name: "hindu_temple", title: "Hindu Temple" },
            { name: "mosque", title: "Mosque" },
            { name: "synagogue", title: "Synagogue" },
        ],
        title: "Worship",
    },
    {
        id: "shopping_mall",
        categories: [
            { name: "shopping_mall", title: "Shopping Mall", default: true },
            { name: "clothing_store", title: "Clothing Store" },
            { name: "department_store", title: "Department Store" },
            { name: "electronics_store", title: "Electronics Store" },
            { name: "furniture_store", title: "Furniture Store" },
            { name: "home_goods_store", title: "Home Goods Store" },
        ],
        title: "Shopping",
    },
    {
        id: "night_club",
        categories: [
            { name: "movie_theater", title: "Movie Theater", default: true },
            { name: "night_club", title: "Night Club" },
            { name: "amusement_park", title: "Amusement Park" },
            { name: "art_gallery", title: "Art Gallery" },
            { name: "casino", title: "Casino" },
            { name: "museum", title: "Museum" },
            { name: "stadium", title: "Stadium" },
        ],
        title: "Entertainment",
    },
    {
        id: "restaurant",
        categories: [
            { name: "restaurant", title: "Restaurant", default: true },
            { name: "bakery", title: "Bakery" },
            { name: "supermarket", title: "Supermarket" },
            { name: "cafe", title: "Cafe" },
        ],
        title: "Food",
    },
    {
        id: "home_goods_store",
        categories: [
            { name: "park", title: "Park", default: true },
            { name: "zoo", title: "Zoo" },
            { name: "aquarium", title: "Aquarium" },
            { name: "tourist_attraction", title: "Tourist Attraction" },
        ],
        title: "Recreation",
    },
    {
        id: "transit_station",
        categories: [
            { name: "transit_station", title: "Transit Station", default: true },
            { name: "airport", title: "Airport" },
            { name: "bus_station", title: "Bus Station" },
            { name: "light_rail_station", title: "Light Rail Station" },
            { name: "subway_station", title: "Subway Station" },
            { name: "taxi_stand", title: "Taxi Stand" },
            { name: "train_station", title: "Train Station" },
        ],
        title: "Transportation",
    },
    {
        id: "hospital",
        categories: [
            { name: "hospital", title: "Hospital", default: true },
            { name: "doctor", title: "Doctor" },
            { name: "physiotherapist", title: "Physiotherapist" },
            { name: "pharmacy", title: "Pharmacy" },
            { name: "veterinary_care", title: "Veterinary Care" },
        ],
        title: "Medical",
    },
];