<template>
  <div class="page">
    <div class="d-flex justify-content-between">
      <div class="p-2">
        <Logo :type="logoType" />
      </div>
      <div class="p-2">
        <ButtonIcon symbol="x" @onClick="closeAppWindow" />
      </div>
    </div>

    <div class="d-flex flex-column page-center">
      <!--Step 1: Language Selection-->

      <p class="step">
        {{ currentStep }}
        {{ this.getTranslation("intro_modal_persona_select_step") }} 2
      </p>
      <p class="community-name">
        {{ community?.c_community_name || "QUEXT FLATS" }}
      </p>

      <div class="p-2 flex-fill" v-if="currentStep == 1">
        <VueScrollPicker
          id="language-scroller"
          :options="options"
          v-model="selectedLanguage"
        />

        <!--Language selection scroll-->
        <p class="option-select">
          {{ this.getTranslation("intro_modal_persona_select_language") }}
        </p>

        <div v-if="selectedLanguage" class="button_container text-center">
          <Button
            @on-click="currentStep = 2"
            :text="getTranslation('intro_modal_persona_select_button_next')"
          />
        </div>
      </div>

      <!--Step 2: Agent Selection-->
      <div class="px-2 flex-fill" v-if="currentStep == 2">
        <div id="persona-slider-wrapper" ref="persona_slider_wrapper">
          <PersonalSlider @onClick="onPersonaClick" :personas="personas" />
        </div>
        <p class="option-select">
          {{ this.getTranslation("intro_modal_persona_select_agent") }}
        </p>

        <div v-if="selectedLanguage" class="button_container text-center">
          <Button
            @on-click="goToDigitalHumanView"
            :text="getTranslation('intro_modal_persona_select_button_begin')"
            class="mb-2"
          />

          <Button
            @on-click="currentStep = 1"
            :text="getTranslation('intro_modal_persona_select_button_back')"
            bgColor="transparent"
            txtColor="var(--secondary-color)"
          />
        </div>
      </div>
    </div>

    <MainFooter />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ButtonIcon from "@/components/Shared/ButtonIcon.vue";
import Button from "@/components/Shared/Button.vue";
import Logo from "@/components/Shared/Logo.vue";
import MainFooter from "@/components/Shared/MainFooter.vue";
import PersonalSlider from "@/components/Shared/PersonalSlider.vue";
import MainService from "@/services/main.service";

export default {
  name: "OnboardingComponent",
  components: {
    Logo,
    ButtonIcon,
    Button,
    MainFooter,
    PersonalSlider,
  },

  data() {
    return {
      currentStep: 1,
      logoType: "dark",
      disabled: "false",
      options: [
        {
          name: "English",
          value: "English",
        },
        {
          name: "Spanish",
          value: "Spanish",
        },
      ],
      selectedLanguage: null,
    };
  },

  created() {
    console.log("Oboarding::created ==>");
    console.log(this.$route);
    console.log(this.animationJSON);
  },

  methods: {
    ...mapActions({
      setLanguage: "translation/setLanguage",
      setCurrentPersona: "main/setCurrentPersona",
    }),
    async goToDigitalHumanView() {
      console.log("goToDigitalHumanView");
      this.$router.push({ name: "DigitalHuman" });
    },
    onPersonaClick(data) {
      console.log("setCurrentPersona", data.name);
      this.setCurrentPersona(data.name);
    },

    handleSelect() {
      console.log("Hello");
    },

    async closeAppWindow() {
      MainService.closeAppWindow();
    },
  },

  computed: {
    ...mapGetters({
      getTranslation: "translation/getTranslation",
      getCommunity: "main/getCommunity",
    }),

    community() {
      return this.getCommunity;
    },

    // animationJSON(){
    //   return this.animationJSONData;
    // }
  },

  watch: {
    selectedLanguage(val) {
      console.log("selectedLanguage", val);
      this.setLanguage(val);
    },
  },
};
</script>

<style scoped>

.page{
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  height: 100vh;
}

.page .page-center{
  flex: 1;
}

.p-2 {
  padding: 24px 16px !important;
}

p {
  font-style: normal;
  font-weight: 700;
  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--grey);
  margin-top: auto;
}

p.option-select {
  font-size: 14px;
  line-height: 18px;
  margin: 30px auto 10px auto;
}

p.step {
  font-size: 16px;
  line-height: 22px;
  /* margin-top: 10% */
}

p.community-name {
  font-size: 32px;
  margin-bottom: 0;
}

#persona-slider-wrapper {
  margin: 10px auto;
  /* width: 100%; */
  max-width: 700px;
  position: relative;
  overflow: hidden;
  height: 250px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 5px;
  gap: 10px;
  width: 342px;
  height: 44px;
  background: var(--secondary-color);
  border-radius: 24px;
}

#language-scroller::v-deep .vue-scroll-picker {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

#language-scroller::v-deep .vue-scroll-picker-layer-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
  /* overflow-y: scroll; */
  background: var(--tertiary-color);
  border-radius: 28px;
  top: calc(45% - 1em);
  bottom: calc(45% - 1em);
}

#language-scroller::v-deep .vue-scroll-picker-item-selected {
  padding: 25px 0;
  position: relative;
  z-index: 1;
  color: var(--black);
  font-size: 30px;
}

#language-scroller::v-deep .vue-scroll-picker-item {
  text-align: center;
  font-size: 30px;
  padding: 12px 0;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: var(--black);
}

#language-scroller::v-deep .vue-scroll-picker-layer-top {
  top: 0;
  height: calc(45% - 1em);
  cursor: pointer;
  border-bottom: 1px var(--white);
  opacity: 0.7;
}
#language-scroller::v-deep .vue-scroll-picker-layer-bottom {
  bottom: 0;
  height: calc(45% - 1em);
  cursor: pointer;
  border-top: 1px var(--white);
  opacity: 0.7;
  color: grey;
}

@media only screen and (max-width: 290px) {
  #persona-slider-wrapper {
    height: initial;
  }
}
</style>