<!-- Slider displayed in the onboarding component -->
/* eslint-disable */
<template>
  <Carousel
    :itemsToShow="1"
    :wrapAround="true"
    :transition="600"
    @slide-end="handleSlideEnd"
  >
    <Slide v-for="persona in this.personas" :key="persona.id">
      <div>
        <img class="my-4 persona" :src="persona.imageIntro" />
      </div>
    </Slide>
    <template #addons>
      <Navigation>
        <template #next>
          <div class="arrow-button-wrapper">
            <ButtonIcon symbol="arrow-right" type="btn-white" bgColor="white" />
          </div>
        </template>
        <template #prev>
          <div class="arrow-button-wrapper">
            <ButtonIcon symbol="arrow-left" type="btn-white" bgColor="white"/>
          </div>
        </template>
      </Navigation>
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { mapGetters } from 'vuex'
import ButtonIcon from '../Shared/ButtonIcon.vue'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'PersonalSlider',
  computed: {
    ...mapGetters({
      getPersonas: 'main/getPersonas',
    }),
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    ButtonIcon,
  },
  data() {
    return {
      personas: [],
    }
  },
  methods: {
    handleSlideEnd(data) {
      this.$emit('onClick', this.personas[data.currentSlideIndex])
    },
  },
  async mounted() {
    this.personas = await this.getPersonas
    console.log(this.personas)
  },
})
</script>

<style scoped>
.persona {
  margin-top: 10px;
  height: 12.5em;
  border: 3px solid var(--secondary-color);
  border-radius: 100%;
  align-content: center;
}

.label {
  color: #999999;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4.5px;
}

.carousel__track {
  transform-style: preserve-3d;
  width: 380px;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}
.carousel__slide--prev > div > .persona {
  background-color: #0000004d !important;
}

.carousel__slide--next > div > .persona {
  background-color: #0000004d !important;
}

.carousel__slide--prev > div {
  margin-left: 16.7em;
}

.carousel__slide--next > div {
  margin-right: 22em;
}

.carousel__prev {
  background-color: #fff;
  border: 2px solid #000;
}

.carousel__slide--prev {
  border-radius: 100%;
  opacity: 0.3;
  transform: rotateY(-10deg) scale(1);
}

.carousel__slide--next {
  border-radius: 100%;
  opacity: 0.3;
  transform: rotateY(10deg) scale(1);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
  justify-content: center;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 290px) {
  .persona {
    height: 7em;
  }

  .arrow-button-wrapper {
    transform: scale(0.7);
  }

  .carousel__slide--prev > div {
    margin-left: 15em;
  }

  .carousel__slide--next > div {
    margin-right: 19em;
  }
}
</style>
