<template>
  <button 
    @click="onClick"
    :disabled="disabled" 
    class="begin-btn"
    :style="backgroundColor + textColor"
  >{{ text }}
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    text: String,
    disabled: Boolean,
    width: String,
    bgColor: String,
    txtColor: String
  },
  data() {
    return {}
  },
  computed: {

    backgroundColor(){
      let bgColor = this.bgColor ? this.bgColor : 'var(--secondary-color)'
      return "background: " + bgColor + ';';
    },

    textColor(){
      let textColor = this.txtColor ? this.txtColor : 'var(--white)'
      return "color: " + textColor  + ';';
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
}
</script>

<style scoped>
.begin-btn {
  justify-content: center;
  align-items: center;
  border: 0px;
  width: 100%;
  height: 44px;
  background: var(--secondary-color);
  border-radius: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--white);
}
</style>
