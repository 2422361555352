<template>
  <div class="wrapper">
    <div v-if="this.$slots['top-left']" class="top">
      <div class="left" v-if="this.$slots['top-left']">
        <slot name="top-left" />
      </div>
      <div class="right" v-if="this.$slots['top-right']">
        <slot name="top-right" />
      </div>
    </div>
    <div class="title" v-if="this.$slots['title']">
      <slot name="title" />
    </div>
    <div class="subtitle" v-if="this.$slots['subtitle']">
      <slot name="subtitle" />
    </div>
    <div class="main" v-if="this.$slots['main']">
      <slot name="main" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalLayout',
}
</script>
<style scoped>
.left {
  color: #E5E5E5 !important;
}

.wrapper {
  height: 100%;
  padding: 35px 0 20px 0;
}

.top {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.top .right {
  padding-left: 20px;
}

.title {
  font-size: 28px;
  margin-bottom: 10px;
  padding: 0 20px;
}

.subtitle {
  font-size: 12px;
  margin-bottom: 6px;
  padding: 0 20px;
}

.main {
  font-size: 12px;
}

.conten {
  padding: 0 20px;
}
</style>
