import TranslationService from '../../services/translation.service'

export default {
  namespaced: true,

  state: {
    translations: null,
    language: 'English',
    languageCode: 'en',
  },

  getters: {
    getLanguage(state) {
      return state.language
    },
    getLanguageCode(state) {
      return state.languageCode
    },
    getTranslation: (state, getters) => (key) => {
      if (state.translations) {
        return state.translations.find((translation) => translation.key == key)[
          getters.getLanguageCode
        ]
      } else {
        return ''
      }
    },
  },

  actions: {
    async setLanguage({ commit }, language) {
      commit('setLanguage', language)

      if (language == 'English') {
        commit('setLanguageCode', 'en')
      }

      if (language == 'Spanish') {
        commit('setLanguageCode', 'es')
      }
    },
    async loadTranslations({ commit }) {
      const translations = await TranslationService.loadTranslations()

      commit('setTranslations', translations)
    },
  },

  mutations: {
    setLanguage(state, language) {
      state.language = language
    },

    setLanguageCode(state, languageCode) {
      state.languageCode = languageCode
    },

    setTranslations(state, translations) {
      state.translations = translations
    },
  },
}
