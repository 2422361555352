export default {
    namespaced: true,

    state: {
        conversations: [],
    },

    getters: {
        getConversations(state) {
            return state.conversations;
        },
        getLastConversation(state) {
            return state.conversations[state.conversations.length - 1];
        },
    },

    actions: {
        async setConversation({ commit }, conversation) {
            commit('setConversation', conversation)
        },
    },

    mutations: {
        setConversation(state, conversation) {
            state.conversations.push(conversation);
        },
    },
}