<template>
  <div class="wrapper-user-input">
    <div class="input-field">
      <input
        v-on:keyup.enter="onEnter"
        v-model="userText"
        type="text"
        id="fname"
        name="fname"
        :placeholder="getTranslation('video_window_chat_placeholder')"
      />
    </div>
    <div class="buttons">
      <ButtonIcon
        symbol="volume-2"
        @onClick="stopSpeaking"
        v-if="!userText && this.volume"
      />
      <ButtonIcon
        symbol="volume-x"
        @onClick="stopSpeaking"
        v-if="!userText && !this.volume"
      />
      <ButtonIcon
        symbol="mic"
        @onClick="mute"
        v-if="!userText && this.microphone"
      />
      <ButtonIcon
        symbol="mic-off"
        @onClick="mute"
        v-if="!userText && !this.microphone"
      />
      <ButtonIcon
        symbol="send"
        @onClick="onEnter"
        v-if="userText"
        text="Send"
        bgColor=""
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ButtonIcon from '../Shared/ButtonIcon.vue'
export default {
  name: 'ChatUserInput',
  components: {
    ButtonIcon,
  },
  data() {
    return {
      userText: '',
      microphone: true,
      volume: true,
      rendered: false
    }
  },
  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
      getPersona: 'sdk/getPersona',
      getRemoteVideo: 'sdk/getRemoteVideo',
      getScene: 'sdk/getScene',
    }),
    persona() {
      return this.getPersona
    },
    remoteVideo() {
      return this.getRemoteVideo
    },
    scene() {
      return this.getScene
    },
  },
  methods: {
    ...mapActions({
      setPreConversation: "conversation/setPreConversation",
    }),
    async onEnter() {
      if (this.persona && this.userText != '') {

        console.log("xxxxxxxxxx #1", this.userText);
        this.setPreConversation(this.userText);

        this.persona
          .conversationSend(this.userText)
          .then()
          .catch((error) => console.log('Request Error', error))
        this.userText = ''
      }
    },
    stopSpeaking() {
      this.volume = this.remoteVideo.muted
      if (this.remoteVideo.muted == false) {
        this.remoteVideo.muted = true
      } else {
        this.remoteVideo.muted = false
      }
    },
    mute() {
      if (this.scene.microphone == false) {
        this.scene.microphone = true
        this.microphone = true
        this.scene.startRecognize()
      } else {
        this.scene.microphone = false
        this.microphone = false
        this.scene.stopRecognize()
      }
      this.$emit('microphoneState', this.microphone);
    },
  },
}
</script>
<style scoped>
.btn-continue {
  padding: 8px 10px;
  gap: 10px;
  width: 36px;
  height: 36px;
  position: relative;
  /* Quext/Pink */
  border-radius: 24px;
  color: var(--white);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.wrapper-user-input {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.input-field {
  flex-grow: 1;
  padding-left: 10px;
  border: none;
}

input[type='text'] {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 1px solid var(--secondary-color);
  color: #999999;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 14px;
  font-style: normal;
}

input[type='text']:focus {
  outline: none;
  border: 1px solid var(--secondary-color);
  color: #1e1f1d;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  flex: 100px 0 0;
}
</style>