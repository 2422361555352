import axios from 'axios'

import { Scene, Persona } from '@soulmachines/smwebsdk'

class SDKService {

    /**
     * 
     * @param {HTML video element} remoteVideo 
     * @returns 
     */
    async createScene(remoteVideo) {

        // Creates the scene
        const scene = new Scene({
            videoElement: remoteVideo,
            requestedMediaDevices: { microphone: true },
            requiredMediaDevices: {},
        });

        scene.sendVideoBounds(
            remoteVideo.clientWidth * remoteVideo.devicePixelRatio,
            remoteVideo.clientHeight * remoteVideo.devicePixelRatio
        );

        // Returns instance
        return scene;
    }

    async connectScene(scene, onConnectionSuccess, onConnectionError, language, currentPersona) {

        console.log("connectScene", language)

        // Loads the URI
        const uri = await this.loadUri();

        // Loads the token
        const token = await this.loadToken(language, currentPersona);

        // Connects to the scene
        await scene.connect({
                tokenServer: {
                    uri: uri,
                    token: token,
                },
            })
            .then((sessionId) => onConnectionSuccess(sessionId))
            .catch((error) => onConnectionError(error));

        // Returns instance
        return scene;
    }

    async createPersona(scene) {
        let persona = new Persona(scene, "1");

        return persona;
    }

    async loadUri() {
        return "wss://dh.soulmachines.cloud";
    }

    async loadToken(language, currentPersona) {

        return new Promise((resolve, reject) => {

            let payload = {
                persona_name: currentPersona,
                language: language
            }

            axios.post(
                `${process.env.VUE_APP_SERVER_ENDPOINT}/token-issuer`,
                payload
            ).then(response => {

                resolve(response.data);

            }, error => {
                console.log("loadToken error", error);
                reject(error);
            });
        });

    }
}

export default new SDKService();