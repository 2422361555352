<template>
  <ModalLayout>

    <template v-slot:title>
      {{ floorplan.name }}
    </template>

    <template v-slot:main>
      <div class="content-wrapper">
        <div
          class="image-container"
          :class="{ 'maximized': isMaximized }"
        >
          <div
            class="zoom-button"
            @click="this.isMaximized ? this.closeMaximize() : this.openMaximize()"
          >
            <vue-feather
              class="zoomicon"
              type="maximize-2"
              size="20"
              @click="toggleMaximize"
            />
          </div>
          <v-zoomer>
            <img
              :src="floorplan.image"
              style="object-fit: contain; width: 100%; height: 100%;"
            >
          </v-zoomer>
          <span style="color: black; font-size: 10px; opacity: 0.5;">
            <p><vue-feather
                class="icon"
                type="search"
                size="8"
              />Double tap to zoom</p>
          </span>
        </div>
        <div class="content-container">
          <div class="content-container-text">
            <div class="title">${{ floorplan.price }}/<span style="font-size: 16px;">mo</span></div>
            <ul>
              <li><img
                  src="@/assets/Icons/BedFrontIcon.svg"
                  class="icon"
                />{{ floorplan.beds }} bedroom</li>
              <li><img
                  src="@/assets/Icons/BathIcon.svg"
                  class="bath-icon"
                />{{ floorplan.half_baths ? floorplan.baths + ".5" : floorplan.baths }} bathrooms</li>
              <li><img
                  src="@/assets/Icons/Square1Icon.svg"
                  class="icon"
                />{{ floorplan.sqft }} sq ft</li>
            </ul>
          </div>
          <!-- <div class="content-container-buttons">
            <div class="available">({{ floorplan.availability }}) Available</div>
            <div class="button-schedule" @onClick="onScheduleClick()">schedule</div>
            <div class="button-apply">apply</div>
          </div> -->
        </div>
      </div>
    </template>

  </ModalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import feather from "feather-icons";
import VueZoomer from 'vue-zoomer/src'

export default {
  name: 'FloorplansDetail',

  props: {
    floorplan: Object,
    minScale: { type: Number, default: 2 },
    maxScale: { type: Number, default: 4 },
  },

  data() {
    return {
      isMaximized: false,
    };
  },

  components: {
    ModalLayout,
    VZoomer: VueZoomer.Zoomer,
  },

  computed: {
    ...mapGetters({
      getCommunity: "main/getCommunity",
    }),

    community() {
      return this.getCommunity;
    },

    maximizeIcon() {
      return this.isMaximized ? 'minimize' : 'maximize';
    },
  },

  mounted() {
    feather.replace();
  },

  methods: {
    async onClickApply() {
      console.log("== onClickApply ==");
    },

    async onScheduleClick() {
      this.$emit('onScheduleClick');
    },
    zoomIn(scale = 0.5) {
      this.tryToScale(scale)
      this.onInteractionEnd()
    },
    zoomOut(scale = 3) {
      this.tryToScale(scale)
      this.onInteractionEnd()
    },
    closeMaximize() {
      this.isMaximized = false;
    },
    openMaximize() {
      this.isMaximized = true;
    },

  }
}
</script>

<style scoped>
.content-wrapper {
  position: absolute;
  right: 0;
  left: 0;
}

.wrapper :deep(.title) {
  font-size: 24px;
  margin-bottom: 15px;
}

.image-container {
  text-align: center;
  background-color: var(--white);
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;
  max-height: 22vh;
  margin: 20px auto 20px auto;
  object-fit: fill
}

.content-container {
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 0px 20px 10px 20px;
}

.content-container>div {
  width: 50%;
}

.content-container-text .title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding-bottom: 12px;
}

.content-container-text .price {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
}

.content-container-text ul {
  padding: 0;
  list-style-type: none;
}

.content-container-text ul li {
  padding: 3px 0;
}

.content-container-text .icon {
  padding-right: 14px;
}

.content-container-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

}

.content-container-buttons .available {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 35px;
  color: var(--tertiary-color);
}

.button-schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #D7C8F1;
  flex: none;
  order: 1;
  /* align-self: stretch; */
  flex-grow: 0;
  background: transparent;
  border-radius: 20px;
  width: 100%;
  height: 27px;
  padding: 0px 10px;
  margin-bottom: 10px;
  color: var(--tertiary-color);
}

.button-apply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: none;
  order: 1;
  /* align-self: stretch; */
  flex-grow: 0;
  background: #8A5ED5;
  border-radius: 20px;
  width: 100%;
  height: 27px;
  padding: 0px 10px;
}

/*temporary-fix until modal size gets updated and dynamic header is added*/
.wrapper {
  padding: 55px 0px 20px;
}

.zoom-button {
  position: absolute;
  right: 5px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  z-index: 2;
}

.zoomicon {
  color: black;
}

.maximized {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: zoom-out;
}

.maximized img {
  max-height: 50vh;
}

.content-container-text .icon {
  height: 15px;
  width: 35px;
  color: white;
}

.content-container-text .bath-icon {
  height: 23px;
  width: 20px;
  margin-right: 15px;
  color: white;
}
</style>