<template>
  <ModalLayout>
    <template v-slot:title>
      availability
    </template>

    <template v-slot:subtitle>
      current units based on preferences
    </template>

    <template v-slot:main>
      <div class="content">
        <div class="results-header">
          <div style="color: #C4C4C4">Results ({{ this.filteredFloorplans.length }})</div>
          <div class="tabs-filter">
            <div class="tabs-container">
              <div
                class="tab"
                @click="filterResults()"
                :class="this.seletedTab == null ? 'active' : ''"
              >all</div>
            </div>
            <div class="tabs-container">
              <div
                class="tab"
                @click="filterResults(1)"
                :class="this.seletedTab == 1 ? 'active' : ''"
              ><span>1br</span></div>
              <div
                class="tab"
                @click="filterResults(2)"
                :class="this.seletedTab == 2 ? 'active' : ''"
              ><span>2br</span></div>
              <div
                class="tab"
                @click="filterResults(3)"
                :class="this.seletedTab == 3 ? 'active' : ''"
              ><span>3br</span></div>
            </div>
          </div>
        </div>
        <div class="results-container">
          <template
            v-for="(floorplan, index) in this.filteredFloorplans"
            :key="index"
          >
            <div
              @click="onFloorplanClick(floorplan)"
              class="result-row"
            >
              <div class="result-row-image">
                <img :src="floorplan.image" />
              </div>
              <div class="result-row-text">
                <div class="result-row-text-name">{{ floorplan.name }}</div>
                <div class="result-row-data">
                  {{ floorplan.beds }} Bed | {{ floorplan.half_baths ? floorplan.baths + ".5" : floorplan.baths }} Bath
                </div>
              </div>
              <div class="result-row-arrow">
                <i class="arrow right"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script>

import ModalLayout from "@/components/Shared/ModalLayout.vue";

export default {
  name: 'FloorplansList',

  props: {
    floorplans: Array,
  },

  data() {
    return {
      localFloorplans: null,
      seletedTab: null
    }
  },

  computed: {
    filteredFloorplans() {
      return this.localFloorplans;
    }
  },

  components: {
    ModalLayout,
  },

  async created() {
    this.localFloorplans = this.floorplans;
  },

  methods: {

    async onFloorplanClick(floorplan) {
      console.log("onFloorplanClick", floorplan);
      this.$emit('onFloorplanClick', floorplan);
    },

    filterResults(numberOfBedrooms = null) {
      switch (numberOfBedrooms) {
        case null:
          this.localFloorplans = this.floorplans;
          break;
        case 1:
          this.localFloorplans = this.floorplans.filter((floorplan) => floorplan.beds == 1);
          break;
        case 2:
          this.localFloorplans = this.floorplans.filter((floorplan) => floorplan.beds == 2);
          break;
        case 3:
          this.localFloorplans = this.floorplans.filter((floorplan) => floorplan.beds == 3);
          break;
      }
      this.seletedTab = numberOfBedrooms
    }
  }
}
</script>

<style scoped>
hr {
  border-top: 1px solid #d64ba1;
}

.title {
  font-size: 24px;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  color: #ffffff;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.tabs-filter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--shadow-purple-color);
  border-radius: 8px;
}

.tab.active {
  color: var(--secondary-color);
  border-bottom: 1px solid var(--white);
}

.tabs-filter div {
  padding: 1px 7px 0;
}

.tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding-inline: 2px;
}

.tabs-container {
  background-color: var(--primary-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 45px;
  margin-inline: 4px;
}

/* -----------------
Result row
----------------- */
.result-row {
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
}

.result-row-image {
  width: 70px;
}

.result-row-image img {
  max-height: 63px;
  width: 100%;
  border-radius: 8px 0 0 8px;
}

.result-row-text {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 20px;
  padding: 10px 20px;
}

.result-row-data {
  font-size: 10px;
}

.result-row-arrow {
  margin-right: 20px;
}

.result-row-text-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 5px;
  /* margin-right: 40px; */
}

.results-container {
  overflow-y: scroll !important;
  max-height: 43vh;
}

.wrapper :deep(.subtitle) {
  margin-bottom: 25px;
}

.wrapper {
  padding: 30px 0 20px 0;
}
</style>