import axios from 'axios'

class MainService {

    async closeAppWindow() {
        // Creates a message to be listened by the JS parent
        const message = {
            action: "onCloseApp"
        };

        // Dispatch message
        window.parent.postMessage(message, "*");
    }

    async expandAppWindow() {
        // Creates a message to be listened by the JS parent
        const message = {
            action: "onExpandApp"
        };

        // Dispatch message
        window.parent.postMessage(message, "*");
    }

    async resetWidthAppWindow() {
        // Creates a message to be listened by the JS parent
        const message = {
            action: "onResetWidthApp"
        };

        // Dispatch message
        window.parent.postMessage(message, "*");
    }


    async loadCommunity(communityId) {
        return axios.get(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/community/${communityId}`
        );
    }
    async loadPersonas() {
        return [{
                name: 'Mia',
                prodUrl: 'wss://madera-video.soulmachines.cloud/',
                devUrl: 'wss://madera-video-dev.soulmachines.cloud/',
                spanishDevUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                spanishProdUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                imageIntro: require('@/assets/intro/mia.png'),
            },
            {
                name: 'Jordan',
                prodUrl: 'wss://madera-jd-vid.soulmachines.cloud/',
                devUrl: 'wss://madera-jd-vid-dev.soulmachines.cloud/',
                spanishDevUrl: 'wss://ddna-madera-jordanspanishdev.soului.dh.soulmachines.cloud/',
                spanishProdUrl: 'wss://sm-ddna-madera-miaspanishdev.dh.soulmachines.cloud/',
                imageIntro: require('@/assets/intro/jordan.png'),
            },
            {
                name: 'Nico',
                prodUrl: 'wss://dh.soulmachines.cloud',
                devUrl: 'wss://dh.soulmachines.cloud',
                spanishDevUrl: 'wss://dh.soulmachines.cloud',
                spanishProdUrl: 'wss://dh.soulmachines.cloud',
                imageIntro: require('@/assets/intro/nico.png'),
            },
        ]
    }

    async saveConversation(payload, communityUUID) {
        // calls api
        return await axios.post(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/community/${communityUUID}/conversation`,
            payload
        )
    }
}

export default new MainService();