<template>
  <ModalLayout>
    <template v-slot:title>
      {{ getTranslation("schedule_tour_review_title_review_details") }}
    </template>
    <template v-slot:main>
      <div class="content">
        <label class="subtitle">{{
          getTranslation("schedule_tour_review_title_appointment_summary")
        }}</label>
        <div class="detail-content">
          <div class="info-row">
            <label class="content-title">{{
              getTranslation("schedule_tour_review_label_date_time")
            }}</label>
            <label class="content-subtitle">{{
              this.date.replace(" ", " @ ")
            }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              getTranslation("schedule_tour_review_label_location")
            }}</label>
            <label class="content-subtitle">{{
              this.community.c_community_name
            }}</label>
          </div>
          <div class="info-row">
            <label
              class="content-title"
              style="display: flex; align-items: flex-start"
            >
              {{ getTranslation("schedule_tour_review_label_contact_details") }}
            </label>
            <div class="contact-info">
              <label class="content-subtitle"
                >{{ this.form.name }} {{ this.form.lastname }}</label
              >
              <label class="content-subtitle">{{
                formatPhoneNumber(this.form.phone)
              }}</label>
              <label class="content-subtitle">{{ this.form.email }}</label>
            </div>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              getTranslation("schedule_tour_review_label_movein_date")
            }}</label>
            <label class="content-subtitle">{{ this.form.moveInDate }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              getTranslation("schedule_tour_review_label_preferences")
            }}</label>
            <label class="content-subtitle">{{
              getLabelBr(this.form.bedrooms)
            }}</label>
          </div>
          <div class="info-row">
            <label class="content-title">{{
              getTranslation("schedule_tour_review_label_price_range")
            }}</label>
            <label class="content-subtitle"
              >${{ this.form.maxBudget }}/MO</label
            >
          </div>
        </div>
      </div>
    </template>
  </ModalLayout>
  <container-buttons
    :textLeft="getTranslation('button_back')"
    :onClickLeft="() => this.onClickPrev()"
    :onClickRight="() => this.submit()"
    :textRight="getTranslation('button_submit')"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import ConversionService from "../../services/conversion.service";
import ContainerButtons from "@/components/Shared/ContainerButtons.vue";
import { v4 as uuidv4 } from "uuid";
import convertNumberToWord from "@/utils/convertNumberToWord";
import moment from "moment";

export default {
  name: "ScheduleTourReview",
  components: {
    ModalLayout,
    ContainerButtons,
  },
  computed: {
    ...mapGetters({
      getTranslation: "translation/getTranslation",
      getCommunity: "main/getCommunity",
    }),
    community() {
      return this.getCommunity;
    },
  },
  methods: {
    async submit() {
      await this.submitClicked();
    },
    async submitClicked() {
      let bedrooms;

      const date = moment(this.form.date, "M/D/YYYY").format("YYYY-MM-DD");
      const time = moment(this.form.time, "h:mm a").format("HH:mm:ss");

      if (this.form.bedrooms && this.form.bedrooms.length) {
        bedrooms = Array.from(this.form.bedrooms).map((v) =>
          this.getValueBr(v)
        );
      } else {
        bedrooms = ["ONE_BEDROOM"];
      }

      // add form data to guest table
      const guestPayload = {
        id_guest: uuidv4(), // missing value
        first_name: this.form.name,
        last_name: this.form.lastname,
        phone_number: this.form.phone,
        email: this.form.email,
        notes: this.form.notes,
      };

      const guestCardPayload = {
        prospect: {
          first_name: this.form.name,
          last_name: this.form.lastname,
          phone: this.form.phone,
          email: this.form.email,
        },
        preferred: {
          move_in_start_date: this.form.moveInDate,
          no_of_bedrooms: bedrooms,
          amount: {
            min: parseInt(this.form.maxBudget[0]),
            max: parseInt(this.form.maxBudget[1]),
          },
        },
        comment: this.form.notes,
        tour_included: true,
        tour_data: {
          layout: bedrooms,
          description: "",
          start: date + " " + time,
          date: date,
          time: time,
        },
        source: "dh",
        community_id: this.community.id_community,
        customer: this.community.customer_id,
      };

      const payload = {
        guest: guestPayload,
        guest_card: guestCardPayload,
      };

      ConversionService.updateGuest(payload);

      this.onClickNext();
    },
    formatPhoneNumber(phoneNumber) {
      const regex = /^(\d{3})(\d{3})(\d{4})$/;
      const formattedNumber = phoneNumber.replace(regex, "+1 ($1) $2-$3");
      return formattedNumber;
    },
    getLabelBr(value) {
      let bedrooms = "";
      if (Array.isArray(value)) {
        value.map(function (value) {
          if (bedrooms === "") bedrooms = bedrooms + value;
          else bedrooms = bedrooms + ", " + value;
        });
      }
      return bedrooms;
    },
    getValueBr(value) {
      console.log("getValueBr", value);

      let numberToWord = convertNumberToWord(+value);
      const bedroomCount = (numberToWord += "_BEDROOM");
      return bedroomCount;
    },
  },
  props: {
    onClickNext: Function,
    onClickPrev: Function,
    form: {
      name: String,
      notes: String,
      lastname: String,
      email: String,
      phone: String,
      maxBudget: Object,
      moveInDate: String,
      date: String,
      time: String,
      errors: Array,
      bedrooms: Array,
      // pets: Object,
      bathrooms: Number,
    },
    date: String,
    moveInDate: String,
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.button-container > div {
  width: 46%;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--tertiary-color);
  /* margin-top: 25px;
  margin-bottom: 25px; */
}

.my-hr {
  border: 0.5px solid var(--secondary-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.detail-content {
  margin-top: 20px;
}

.info-row {
  display: flex;
  margin-bottom: 14px;
}

.content-title {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: var(--tertiary-color);
  width: 40%;
}

.content-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  color: var(--white);
}

.contact-info {
  flex-direction: column;
}
</style>
