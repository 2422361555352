<template>
    <div class="info-tooltip" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <span class="info-icon" :class="{ 'active': showTooltip }">
        <i data-feather="info" class="info-icon"></i>
      </span>
      <div class="tooltip-text" :class="tooltipClass" :text="tooltipText" v-show="showTooltip">{{ tooltipText }}</div>
    </div>
</template>
  
<script>
import feather from 'feather-icons'
  
export default {
    name: 'ToolTip',
    components: {
    },

    props: {
      text: {
        type: String,
        required: true
      },
      direction: {
        type: String,
        default: 'top',
        validator: direction => ['top', 'bottom', 'left', 'right'].includes(direction)
      }
    },

    async mounted(){
        feather.replace();
    },

    data() {
      return {
        showTooltip: false
      }
    },

    computed: {
      tooltipText() {
        return this.text || ''
      },

      tooltipClass() {
        return `tooltip-${this.direction}`
      }
    }
}
</script>
  
<style scoped>
.info-tooltip {
    display: inline-block;
    position: relative;
}

.info-icon:hover{
  color: var(--secondary-color);
}

.info-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
     color: var(--white);
    transition: all 0.2s ease;
}

.info-icon.active {
    color: var(--secondary-color);
}

.tooltip-text {
    display: none;
    position: absolute;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: var(--secondary-color);
    color: #fff;
    font-size: 0.875rem;
    white-space: wrap;
    width: 166px;
    height: 80px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    border-radius: 10px;
    text-align: center;
    margin: 10px 5px 5px 5px ;
}

.tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}
  
.tooltip-right {
    top: 50%;
    left: 100%;
    transform: translateY(-45%);
    height: auto;
}

.info-icon.active + .tooltip-text {
    display: block;
}

.tooltip-text::before {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    border-width: 8px 8px 0 8px;
    border-style: solid;
    bottom: 50%;
    margin-left: -25px;
    border-color: transparent transparent transparent var(--secondary-color);
    transform: rotate(7.132rad);
}
</style>
  
