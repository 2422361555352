<template>
  <div id="intro">
    <Vue3Lottie 
        :animationData="animationJSON" 
        :height="360" 
        :width="360" 
        @onLoopComplete="onAnimationComplete"
    />
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";

import animationJSONData from "@/assets/files/animation.json";

export default {
  name: "IntroComponent",

  data() {
    return {
      animationJSON: animationJSONData,
    };
  },
  methods: {
    ...mapActions({
      setIntroIsShowing: "main/setIntroIsShowing",
    }),
    async onAnimationComplete(){

        // var that = this;

        // setTimeout(function() { 
        //     that.setIntroIsShowing(false);
        // }, 500);
    },
  },
};
</script>
  
  <style scoped>
#intro {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  /* background-color: rgba(51, 49, 54, 0.904); */
  background-color: #d7c8f1;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  