import axios from 'axios'

class AvailabilityService {

    async checkAvailability(payload) {
        return await axios
            .post(`${process.env.VUE_APP_SERVER_ENDPOINT}/unit/availability/data`, payload,{
                headers: {
                  "Access-Control-Allow-Origin": '*'
                }
              })
            .then(
                (response) => {
                    console.log("== checkAvailability response ==", response);
                    return response.data.data.availability;
                },
                (error) => {
                    console.log("== checkAvailability error ==", error);
                }
            )
    }
}

export default new AvailabilityService()