<template>
  <div
    class="toggle-buttons"
    :class="[
      size === 'large' ? 'toggle-buttons--size-l' : 'toggle-buttons--size-s',
      { 'toggle-buttons--disabled': disabled },
    ]"
  >
    <button
      type="button"
      class="toggle-buttons__button"
      v-for="option in options"
      :key="option.key"
      @click="
        () => {
          /**
           * Emitted on value change
           */
          $emit('change', option.key)
        }
      "
      :class="[
        value === option.key ? 'toggle-buttons__button--active' : '',
        buttonClass,
      ]"
      :disabled="disabled"
    >
      <span class="textButton">{{ option.value }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * ToggleButton value
     */
    value: {
      type: String,
      required: false,
    },
    /**
     * Array of available options
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Component size `small | large`
     */
    size: {
      type: String,
      default: 'small',
      validation: function (value) {
        return ['small', 'large'].indexOf(value) !== -1
      },
    },
    /**
     * Should the control be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom class added to button element
     */
    buttonClass: {
      type: String,
      default: '',
    },
    /**
     * Interpret `value` as name of icon
     */
    withIcons: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],
}
</script>

<style scoped>
.toggle-buttons {
  grid-template-columns: repeat(3, 1fr);
}

.toggle-buttons__button {
  width: 33%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 5cm;
  border-color: transparent;
  justify-content: center;
  align-items: center;
  color: var(--white);
  /* @apply h-6 font-normal text-xs bg-active-100 border border-active-200 text-highlight-400; */
}
.toggle-buttons__button:hover {
  border-radius: 5cm;
  padding: 10px;
}

.toggle-buttons__button--active {
  padding: 10px;
  color: #32224e;
  background-color: var(--tertiary-color);
  border-radius: 5cm;
}
.textButton {
  font-size: 13px;
  font-weight: bold;
}
</style>
