<template>
  <div>
    <div v-if="!options || options?.length === 0">No bedrooms available</div>
    <div v-if="options?.length" class="input-field-button">
      <div v-for="(number, index) in options" :key="index">
        <button
          :class="`button ${
            activeButtonValue.includes(`${number}`) ? 'active' : ''
          }`"
          @click="onButtonClick(`${number}`)"
        >
          <div class="content-button">{{ number }}</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "MultiButton",
  props: {
    activeButton: {
      type: Array,
      default: null,
    },
    ScheduleTour: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      activeButtonValue: Array.isArray(this.activeButton)
        ? [...this.activeButton]
        : [],
    };
  },
  watch: {
    activeButton(newValue) {
      this.activeButtonValue = newValue;
    },
  },
  methods: {
    onButtonClick(buttonIndex) {
      if (!this.activeButtonValue.includes(buttonIndex)) {
        this.activeButtonValue.push(buttonIndex);
      } else {
        const deleteRoom = this.activeButtonValue.filter(
          (bedroom) => bedroom !== buttonIndex
        );
        this.activeButtonValue = deleteRoom;
      }
      this.$emit("update:activeButton", [...this.activeButtonValue]);
    },
  },
};
</script>
<style scoped>
.input-field-button {
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 35px;
  margin-bottom: 20px;
}
.space-right {
  margin-right: 32px;
}
.active {
  border: 1px solid var(--secondary-color);
  padding: 2px !important;
  border-radius: 8px !important;
  color: #101828 !important;
  background-color: #f9fafb;
  height: 40px !important;
  width: 40px !important;
}
.button {
  border: none;
  color: var(--primary-color);
  font-weight: 500;
  font: inherit;
  cursor: pointer;
  padding: 0px;
  border-radius: 8px;
  width: 35px;
  height: 35px;
}
.content-button {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  border-radius: 8px;
  padding: 3px;
  font-size: 18px;
}
</style>
