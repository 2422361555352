<!-- Displays logo dark and light -->
<template>
  <div class="logo" style="width: 77px">
    <img v-if="type == 'light'"
      class="top-right" 
      src="@/assets/quext_logo_white.png"
      alt="Quext"
    />

    <img v-if="type == 'dark'"
      class="top-right"
      src="@/assets/quext_logo.jpg"
      alt="Quext"
    />
  </div>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: {
    type: String,
  },
}
</script>

<style scoped>
.top-right{
  position: relative;
  max-width: 80px;
}
</style>
