import MapService from '@/services/map.service';

export default {
    namespaced: true,

    state: {
        schools: '',
        buttonsFilter: '',
        clickedCategory: '',
        showMapSuggestions: false
    },

    getters: {
        getSchools(state) {
            return state.schools;
        },

        getButtonsFilter(state) {
            return state.buttonsFilter;
        },

        getClickedCategory(state) {
            return state.clickedCategory;
        },

        getShowMapSuggestions(state) {
            return state.showMapSuggestions;
        },
    },

    actions: {
        async fetchSchools({ commit }, communityUUID) {
            let schools = await MapService.getSchools(communityUUID);
            commit('setSchools', schools);
        },

        async loadButtonsFilter({ commit }) {
            let buttonsFilter = await MapService.getbuttonsFilter();
            commit('setButtonsFilter', buttonsFilter);
        },

        async setClickedCategory({ commit }, clickedCategory) {
            commit('setClickedCategory', clickedCategory);
        },

        async setShowMapSuggestions({ commit }, showMapSuggestions) {
            commit('setShowMapSuggestions', showMapSuggestions);
        },
    },

    mutations: {
        setSchools(state, schools) {
            state.schools = schools;
        },

        setButtonsFilter(state, buttonsFilter) {
            state.buttonsFilter = buttonsFilter;
        },

        setClickedCategory(state, clickedCategory) {
            state.clickedCategory = clickedCategory;
        },

        setShowMapSuggestions(state, showMapSuggestions) {
            state.showMapSuggestions = showMapSuggestions;
        },
    }
}