<template>
  <ModalLayout>
    <template v-slot:title>
      <div v-if="date" style="font-size: 28px">
        {{ getHeaderDate(date) }}
      </div>
      <div v-else style="font-size: 28px">
        {{ getTranslation('date_top_right_tour') }}
      </div>
      <hr style="border-top: 1px solid var(--secondary-color)" />
    </template>
    <template v-slot:main>
      <div class="content content-schedule">
        <VueDatePicker
          v-model="date"
          @update:model-value="setDate"
          inline
          auto-apply
          hide-offset-dates
          :dark="true"
          :enable-time-picker="false"
          :locale="this.getLanguageCode"
          :week-start="0"
          :disabled-week-days="[0]"
          :disabled-dates="disabledDates"
          month-name-format="long"
          :month-change-on-scroll="false"
        />
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'CustomDatePicker',

  components: {
    ModalLayout,
    VueDatePicker,
  },
  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
      getLanguageCode: 'translation/getLanguageCode',
    }),
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      date: this.value,
    }
  },

  watch: {
    value(newValue) {
      this.date = newValue
    },
  },

  methods: {
    getHeaderDate(dateString) {
      const date = new Date(dateString)
      const options = {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
      }
      const parts = new Intl.DateTimeFormat(
        this.getLanguageCode,
        options
      ).formatToParts(date)
      const month = parts.find((part) => part.type === 'month').value
      const day = parts.find((part) => part.type === 'day').value
      const weekday = parts.find((part) => part.type === 'weekday').value
      return `${weekday.charAt(0).toUpperCase()}${weekday.slice(1)}, ${month
        .charAt(0)
        .toUpperCase()}${month.slice(1)} ${day}`
    },

    setDate(value) {
      this.date = value
      var day = this.date.getDate()
      var month = this.date.getMonth() + 1
      var year = this.date.getFullYear()
      var datePicked = month + '/' + day + '/' + year
      const userRequest = { userText: datePicked, inputType: 'clicked' }
      this.$emit('update:value', this.date)
      this.$emit('dateSelected', userRequest)
    },

    disabledDates(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date.getTime() < today.getTime()
    },
  },
}
</script>

<style>
.content {
  padding: 0 20px;
}

.content-title {
  font-size: 28px;
}

hr {
  border-top: 1px solid var(--secondary-color);
}

.dp__theme_dark {
  --dp-background-color: var(--primary-color);
  --dp-border-color: var(--primary-color);
  --dp-menu-border-color: var(--primary-color);
  --dp-primary-text-color: var(--black);
  --dp-disabled-color-text: var(--grey);
}

.dp__cell_inner {
  border-radius: var(--dp-border-radius, 21px);
  padding: 20px;
}

.dp__calendar_header_item {
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 4px;
}

.dp__overlay_cell,
.dp__overlay_cell_active,
.dp__month_year_select,
.dp__calendar_header_item {
  text-transform: capitalize;
}

.dp__menu {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}

.dp__month_year_row {
  padding-bottom: 2em;
  padding-top: 1em;
}

.dp__active_date {
  background: var(--tertiary-color);
}

.dp__today {
  border: 1px solid var(--tertiary-color);
}

.dp__overlay_cell_active {
  cursor: pointer;
  border-radius: var(--dp-border-radius, 4px);
  text-align: center;
  background: var(--tertiary-color);
  color: var(--black);
}

.dp__outer_menu_wrap {
  height: 50px;
}

@media only screen and (max-height: 700px) {
  .content-schedule {
    height: 24em;
  }
}
</style>
