<template>
  <div
    class="wrapper-modal"
    :class="(childIsExpanded && this.modal == 'Gallery') ? 'is-expanded' : ''"
  >
    <div class="content-modal">
      <img
        v-if="showLogo"
        src="@/assets/quext_logo_white.png"
        class="modal-logo"
      />
      <ButtonIcon
        @onClick="closeModal"
        symbol="x"
        bgColor="transparent"
        id="modal-close"
      />
      <component
        v-if="modalType != null"
        v-bind:is="modalType"
        @onExpanded="onExpanded"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
// import { nextTick } from 'vue'
import ScheduleTour from "@/components/ScheduleTour/ScheduleTourIndex.vue";
import ContactForm from "@/components/ContactForm/ContactFormIndex.vue";
import Floorplans from "@/components/Floorplans/FloorplansIndex.vue";
import Gallery from "@/components/Gallery/GalleryIndex.vue";
import Neighborhood from "@/components/Neighborhood/NeighborhoodIndex.vue";
import ButtonIcon from "@/components/Shared/ButtonIcon.vue";
import MainService from '@/services/main.service'

export default {
  name: 'ModalComponent',

  components: {
    ScheduleTour,
    ContactForm,
    Gallery,
    Floorplans,
    Neighborhood,
    ButtonIcon
  },

  data() {
    return {
      modal: null,
      showLogo: true,
      childExpanded: false,
    }
  },

  computed: {
    ...mapGetters({
      getLastConversation: "conversation/getLastConversation",
      getModal: "main/getModal",
    }),

    storedModal() {
      return this.getModal;
    },

    childIsExpanded() {
      return this.childExpanded;
    },

    // Last conversation added to the array
    lastConversation() {
      return this.getLastConversation;
    },

    modalType() {
      return this.modal;
    },
  },

  watch: {

    // Keeps watching the new conversations in order to check if the conversation
    // has changes for the menu
    lastConversation(newConversation) {
      console.log("Modal last conversation => ", newConversation);

      if (newConversation && newConversation.intentName) {

        const isCard = this.checkIsCard(newConversation.fulfillmentMessages);
        console.log("isCard", isCard);

        if (isCard) {
          this.modal = null;
          this.modal = "Gallery";
          this.setModal(this.modal);
          this.showLogo = true;
        } else {
          this.chooseModal(newConversation.intentName, newConversation.output);
        }
      }
    },

    storedModal(newValue) {
      if (newValue == null) {
        this.modal = null;
      }
    }
  },

  methods: {

    ...mapActions({
      setModal: "main/setModal",
      toggleExpandMenu: "menu/toggleExpandMenu"
    }),

    onExpanded() {

      if (window.innerWidth == 380) {
        this.childExpanded = true;
      } else {
        this.childExpanded = false;
      }

    },

    checkIsCard(fulfillmentMessages) {
      return fulfillmentMessages.find(message => 'card' in message);
    },

    async chooseModal(intentName, output) {
      console.log("chooseModal #1", intentName);

      this.modal = null;

      switch (intentName) {
        case 'Schedule_tour.calendar':
          this.modal = "ScheduleTour";
          this.showLogo = true;
          break;

        case 'availability.menu.show_contact_form':
        case 'Avalability.menu':
        case 'userAsks.noSpecificResponse.next.schedule_tourOrmainMenu':
        case "Availability.all_units":
        case "Availability.all_units_back":
        case "Availability.floorplan_by_price":
        case "Availability.floorplan_by_square_feet":
        case "Availability.floorplan_by_square_feet_back":
        case "Availability.menu.From_contact_form":
        case "Availability.select_bedroom_number":
        case "Availability.select_bedroom_number_back":
        case "Avalability.smaller_than_sqft_select_floorplan":
        case "Avalability.select_floorplan":
        case "Avalability.select_floorplan_back":
        case "Avalability.select_highest_units":
        case "Avalability.select_lowest_units":
        case "Avalability.select_lowest_units_back":
        case "Avalability.higher_than_price_unit_list ":
        case "Avalability.larger_than_sqft_units_list ":
        case "Avalability.lower_than_price_unit_list ":
        case "Avalability.higher_than_price_select_floorplan":
        case "Avalability.higher_than_price_unit_list_back":
        case "Avalability.larger_than_sqft_units_list_back":
        case "Avalability.larger_than_sqft_select_floorplan":
        case "Avalability.lower_than_price_select_floorplan":
        case "Avalability.lower_than_price_unit_list_back":
        case "Availability.all_units_select_floorplan":
        case "moreThanThreeBed":
        case "Apartments.floor_plan":
          this.modal = "Floorplans";
          this.showLogo = true;
          break;

        case 'Amenities.the_neighborhood':
        case 'neighborhood.Neighborhood.general':
          this.modal = "Neighborhood";
          this.showLogo = false;
          break;
      }

      if (this.modal == null) {
        if (await this.checkMapIsInResponse(output)) {
          this.modal = "Neighborhood";
        } else {
          // TODO: post to Qoops
        }
      }

      this.setModal(this.modal);
    },

    // Checks the word "map" in the response message
    async checkMapIsInResponse(output) {
      return output.toLowerCase().includes('map');
    },

    async closeModal() {
      MainService.resetWidthAppWindow();
      this.setModal(null);
      this.childExpanded = false;
      this.modal = null;
    },
    async toggleMenu() {
      this.toggleExpandMenu(true); 
    } 
  },
}
</script>

<style scoped>
.wrapper-modal {
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 40px 20px;
}


.is-expanded {
  backdrop-filter: blur(100px);
  padding: 0;
}


.content-modal {
  background-color: #49454F;
  border-radius: 30px 30px 30px 30px;
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

#modal-close {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 900;
}

.modal-logo {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 900;
  width: 77px;
  height: 40px;
  filter: drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.08)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.04));
}

#modal-close :deep(svg) {
  padding: 5px;
  filter: drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.08)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.04));
}

.modal-logo :deep(svg) {
  padding: 5px;
}
</style>