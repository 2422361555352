<template>
  <div class="dropdown input-container">
    <label :for="inputId" class="input-label">{{ label }}</label>
    <button class="dropdown-toggle input-field" @click="toggleDropdown">
      {{ selectedOption?.label || placeholder }}
      <i data-feather="chevron-down" color="#8a5ed5" class="arrow-icon"></i>
    </button>
    <div class="dropdown-menu" :class="{ open: isOpen }">
      <button
        v-for="(option, i) in options"
        :key="i"
        @click="selectOption(option)"
        :class="`${i === 0 && 'border-top'} ${
          i === options.length - 1 && 'border-bottom'
        }`"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
import feather from 'feather-icons'

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.selectedOption = newValue
    },
  },
  computed: {
    inputId() {
      return 'input-' + Math.floor(Math.random() * 100000)
    },
  },
  async mounted() {
    feather.replace()
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },
    selectOption(option) {
      this.selectedOption = option
      this.$emit('input', option)
      this.isOpen = false
    },
  },
}
</script>

<style scoped>
.input-field {
  padding: 10px 35px 10px 10px; 
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  text-align: start;
  position: relative; 
  font-size: 15px;
}

.dropdown-toggle::after {
  display: none;
}

.input-field {
  padding: 20px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  text-align: start;
  margin-left: 10px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 2;
  width: 30vw; 
  max-width: 300px; 
  background-color: var(--primary-color);
  border: none;
  left: 15%; 
  margin-left: 10px;
  padding: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.dropdown-menu.open {
  display: block;
}
.dropdown-menu button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-top: 1px solid #846bb1 !important; 
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid var(--base-color) !important;
  cursor: pointer;
  font-size: 12px;
  border-radius: 10px;
}

.border-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 14px;
  color: white;
}
</style>
