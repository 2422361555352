<template>
  <ModalLayout>
    <template v-slot:top-left>
      <div class="header-container">
        <div class="header-container-icon">
          <vue-feather
            type="map-pin"
            size="20"
          ></vue-feather>
        </div>
        <div>
          <div class="title">{{ community.c_city }}</div>
          <div class="subtitle">{{ community.c_address }}</div>
        </div>
      </div>
    </template>
    <template v-slot:main>
      <div class="content">
        <div
          v-show="expanded"
          class="menu-content"
        >
          <ul>
            <template
              v-for="(item, index) in this.buttonsFilter"
              :key="index"
            >
              <li
                @click="onClickMenuItem(item)"
                :class="(selectedCategory != null && selectedCategory.id == item.id) ? 'selected' : ''"
              >
                {{ item.title }}
              </li>
            </template>
          </ul>
        </div>
        <div class="menu-container">
          <div
            v-if="selectedCategory != null"
            id="button-dropdown"
            @click="expanded = !expanded"
          >
            {{ selectedCategory.title }} <span class="arrow-container"><i
                :class="this.expanded ? 'arrow down' : 'arrow up'"
              ></i></span>
          </div>
        </div>
        <div class="content-container">
          <div
            v-if="currentDisplayIsList"
            id="poi-list"
          >

            <div class="marker-row-container">
              <div class="schools-near-you"><strong>{{ this.markers.length }}</strong> {{ selectedCategory.title }} near
                you</div>
              <template
                v-for="(marker, index) in this.markers"
                :key="index"
              >
                <div
                  :class="index == clickedPoi
                    ? 'marker-row marker-row-selected'
                    : 'marker-row'
                    "
                  v-if="marker.name"
                  @click="onRowClick(index)"
                >
                  <div class="marker-row-header">
                    <div class="marker-row-title">{{ marker.name }}</div>
                    <div class="float-right"  v-if="this.selectedPoi == index">
                      <img class="trademark" src="/markers/GS.org_logo_main@1x.png">
                    </div> 
                  </div>
                  <div
                    class="marker-row-detail"
                    v-if="this.selectedPoi == index"
                  >
                    <div class="marker-row-bottom">
                      <div class="marker-row-vicinity">
                        {{ marker.vicinity }}
                      </div>
                      <div
                        v-if="marker.rating"
                        class="marker-row-rating"
                      >
                        {{ marker.rating }}
                      </div>
                    </div>
                    <div
                      v-if="selectedCategory.id == 'school'"
                      class="my-2"
                    >
                      <div class="row">
                        <div class="col-3">
                          <span class="label-detail">Details</span>
                        </div>
                        <div class="col-3">
                          <vue-feather
                            v-if="marker.level_range"
                            type="award"
                            size="10"
                          ></vue-feather>
                          {{ marker.level_range }}
                        </div>
                        <div class="col-6">
                          <template v-if="marker.distance">
                            <vue-feather
                              type="map"
                              size="10"
                            ></vue-feather>
                            {{ parseInt(marker.distance / 1.6) }} miles
                          </template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3 offset-3">
                          <vue-feather
                            v-if="marker.school_type"
                            type="users"
                            size="10"
                          ></vue-feather>
                          {{ marker.school_type }}
                        </div>
                        <div class="col">
                          <vue-feather
                            v-if="marker.phone"
                            type="phone"
                            size="10"
                          ></vue-feather>
                          {{ marker.phone }}
                        </div>
                      </div>
                    </div>

                    <div class="marker-row-detail-bottom">
                      <Button
                        v-if="marker.profile"
                        @on-click="onClickExternal(marker.profile)"
                        text="school profile"
                      />
                      <Button
                        v-if="marker.website"
                        @on-click="onClickExternal(marker.website)"
                        text="visit website"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <GoogleMap
            v-if="!this.currentDisplayIsList"
            ref="mapRef"
            api-key="AIzaSyBaLS1XphPQcrOH5PU3dLpoQlYtAP53sd8"
            style="width: 100%; height: 100%"
            :center="{
              lat: this.community.latitude,
              lng: this.community.longitude,
            }"
            :zoom="this.zoomDefault"
            map-type-id="terrain"
            zoom-control="false"
            scale-control="false"
            control-size="false"
            pan-control="false"
            map-type-control="false"
            street-view-control="false"
            aria-controls="false"
            map-type-control-options="false"
            rotate-control="false"
            fullscreen-control="false"
          >
            <ButtonIcon
              v-if="!this.isMobile"
              class="map-expand-button"
              symbol="maximize-2"
              @onClick="onMapExpand"
              bgColor="#3E2A62"
            />
            <template
              :key="index"
              v-for="(marker, index) in this.theMarkers"
            >
              <Marker :options="{
                position: marker,
                icon: {
                  url: marker.markerImage,
                },
                zIndex: index
              }">
                <InfoWindow>
                  <div class="info-window-content">
                    <div class="poi-name">
                      <a :href="marker.profile" target="_blank" style="color: blue;">{{ marker.name }}</a>
                    </div>
                     <div class="poi-rating">{{ marker.rating }}</div>
                    <div class="poi-vicinity">{{ marker.vicinity }}</div>
                    <template v-if="marker.school_type">
                      <div class="poi-level-range">{{ marker.level_range }} (grades serviced)</div>
                      <div class="float-right">
                        <img class="trademark" src="/markers/GS.org_logo_main@1x.png">
                      </div> 
                    </template>
                  </div>
                </InfoWindow>
              </Marker>
            </template>
          </GoogleMap>
        </div>

        <div class="bottom">
          <Button
            style="color: #d7c8f1"
            :text="currentDisplayIsList ? 'Switch to map' : 'Switch to list'"
            @onClick="onSwitch"
            bgColor="transparent"
          />
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import ModalLayout from "@/components/Shared/ModalLayout.vue";
import { MapOptions } from "./MapOptions";
import ButtonIcon from "../Shared/ButtonIcon.vue";
import Button from "@/components/Shared/Button.vue";
import { GoogleMap, Marker, InfoWindow/*, CustomControl*/ } from "vue3-google-map";
import { mapGetters, mapActions } from "vuex";
import feather from "feather-icons";
import MainService from '@/services/main.service'

export default {
  name: "NeighborhoodIndex",

  components: {
    ModalLayout,
    GoogleMap,
    Marker,
    Button,
    InfoWindow,
    ButtonIcon
  },

  data() {
    return {
      center: { lat: 51.093048, lng: 6.84212 },
      options: MapOptions,
      ready: false,
      expanded: false,
      markers: [],

      defaultRadius: 7000,

      zoomDefault: 14,

      zoomMarker: 16,

      // Google Places Service object
      placesService: null,

      map: null,

      selectedCategory: {
        id: "shopping_mall",
        title: "Shopping"
      },

      selectedPoi: null,

      currentDisplayIsList: false,

      initialized: false,

      markersImage: [
        { id: "shopping_mall", url: "map-pin_shopping.svg" },
        { id: "school", url: "map-pin_school.svg" },
        { id: "church", url: "map-pin_worship.svg" },
        { id: "night_club", url: "map-pin_entertainment.svg" },
        { id: "restaurant", url: "map-pin_food.svg" },
        { id: "home_goods_store", url: "map-pin_recreation.svg" },
        { id: "transit_station", url: "map-pin_transport.svg" },
        { id: "hospital", url: "map-pin_medical.svg" },
      ],

      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters({
      getSchools: "map/getSchools",
      getButtonsFilter: "map/getButtonsFilter",
      getCommunity: "main/getCommunity",
    }),

    theMarkers() {
      return this.markers;
    },

    schools() {
      return this.getSchools;
    },

    community() {
      return this.getCommunity;
    },

    clickedPoi() {
      return this.selectedPoi;
    },

    buttonsFilter() {
    if (this.schools && this.schools.length === 0) {
      return this.getButtonsFilter.filter(item => item.id !== 'school');
    } else {
      return this.getButtonsFilter;
    }
  },

    isReady() {
      return this.ready;
    },

    isMobile() {
      // 700 width + 10 left, 10 right
      return this.windowWidth <= 720
    }
  },

  async created() {
    await this.fetchSchools(this.community.id_community);
    await this.loadButtonsFilter();
  },

  expandButtonDisplay() {

  },

  async mounted() {
    feather.replace();

    let that = this;

    window.addEventListener('resize', function () {
      that.windowWidth = window.innerWidth
    });

    // https://vuejs.org/api/component-instance.html#watch
    this.$watch(
      () => {
        // This return needs to have this order
        if(!this.schools.length) {
          return this.$refs.mapRef && this.$refs.mapRef.ready;
        } else {
          return this.schools.length && this.$refs.mapRef && this.$refs.mapRef.ready;
        }
      },
      (val) => {
        if (val) {
          this.ready = val;
          this.init();
        }
      }
    );

  },

  methods: {
    ...mapActions({
      fetchSchools: "map/fetchSchools",
      loadButtonsFilter: "map/loadButtonsFilter",
    }),

    async init() {

      // Gets Map object
      this.map = this.$refs.mapRef.map;

      // Gets Places Service
      this.placesService = new this.$refs.mapRef.api.places.PlacesService(
        this.map
      );

      this.selectedCategory = {
        id: "shopping_mall",
        title: "Shopping"
      };

      if (this.selectedCategory.id === "school") {
        await this.formatResponseSchools();
      } else {
        await this.nearbySearch(this.selectedCategory.id, this.selectedCategory.title);
      }
    },

    async formatResponseSchools() {

      // Clears markers
      this.markers = [];

      // Insers the category markes
      for (var i = 0; i < this.schools.length; i++) {
        const position = {
          name: this.schools[i].school_name,
          lat: parseFloat(this.schools[i].latitude),
          lng: parseFloat(this.schools[i].longitude),
          rating: this.schools[i].greatschools_rating
            ? "Rating: " + this.schools[i].greatschools_rating + " / 10"
            : "No Rating",
          vicinity:
            this.schools[i].street +
            ' ' +
            this.schools[i].city +
            ", " +
            this.schools[i].state +
            "-" +
            this.schools[i].zip,

          website: this.schools[i].school_website
            ? this.schools[i].school_website
            : "",
          profile: this.schools[i].greatschools_url
            ? this.schools[i].greatschools_url
            : "",
          level_range: this.schools[i].level_range
            ? this.schools[i].level_range
            : "",
          school_type: this.schools[i].school_type
            ? this.schools[i].school_type
            : "",
          phone: this.schools[i].phone ? this.schools[i].phone : "",
          distance: this.schools[i].direct_distance_in_meters
            ? this.schools[i].direct_distance_in_meters
            : "",
          markerImage: "markers/map-pin_school.svg"
        };

        this.markers.push(position);
      }

      // Push the community marker
      this.markers.push({
        lat: this.community.latitude,
        lng: this.community.longitude,
        name: this.community.c_community_name,
        rating: "",
        vicinity: "",
        markerImage: "markers/map-pin_home.svg",
      });

      // Fits the markers into the space of the map
      var bounds = new this.$refs.mapRef.api.LatLngBounds();

      for (var j = 0; j < this.markers.length; j++) {
        bounds.extend(this.markers[j]);
      }

      this.map.fitBounds(bounds);
    },

    async formatResponseLocations(results, placeType) {

      // Clears markers
      this.markers = [];

      var markerImage;

      // Insers the category markes
      for (var i = 0; i < results.length; i++) {

        markerImage = this.markersImage.filter(function (elem) {
          return elem.id == placeType;
        });

        const position = {
          name: results[i].name,
          lat: results[i].geometry.location.lat(),
          lng: results[i].geometry.location.lng(),
          rating: results[i].rating + " / 5",
          vicinity: results[i].vicinity,
          markerImage: "markers/" + markerImage[0].url,
        };

        this.markers.push(position);
      }

      // Push the community marker
      this.markers.push({
        lat: this.community.latitude,
        lng: this.community.longitude,
        name: this.community.c_community_name,
        rating: "",
        vicinity: "",
        markerImage: "markers/map-pin_home.svg",
      });

      // Fits the markers into the space of the map
      var bounds = new this.$refs.mapRef.api.LatLngBounds();

      for (var j = 0; j < this.markers.length; j++) {
        bounds.extend(this.markers[j]);
      }

      this.map.fitBounds(bounds);
    },

    async nearbySearch(placeType, placeKeyword) {
      // Request payload
      const request = {
        // Location of the map
        location: {
          lat: this.community.latitude,
          lng: this.community.longitude,
        },
        radius: this.defaultRadius,
        // rankBy: google.maps.places.RankBy.DISTANCE,
        type: [placeType],
        // bounds: this.map.getBounds(),
        keyword: placeKeyword,
      };

      this.placesService.nearbySearch(request, (results, status) => {
        if (status == "OK") {
          this.formatResponseLocations(results, placeType);
        } else {
          console.log("Problem loading POIs");
        }
      });
    },

    async onClickMenuItem(item) {
      this.selectedCategory = item;

      if (item.id == "school") {
        this.formatResponseSchools();
      } else {
        await this.nearbySearch(item.id, item.title);
      }

      this.expanded = false;
    },

    async onRowClick(index) {

      // Saves the selected POI index
      this.selectedPoi = index;

      // Gets the selected marker
      let selectedMarker = this.markers[index];

      // Sets the center of the map
      this.map.setCenter({ lat: selectedMarker.lat, lng: selectedMarker.lng });

      // Updates the zoom to focus the POI
      this.map.setZoom(this.zoomMarker);

      this.expanded = false;
    },

    async onClickExternal(website) {
      window.open(website, "_blank", "noreferrer");
    },

    async onBackClick() {
      console.log("== onBackClick ==");
      this.selectedCategory = null;
    },

    onSwitch() {
      this.currentDisplayIsList = !this.currentDisplayIsList;
    },

    onMenuDropdown() {
      console.log("== onMenuDropdown ==")
    },

    onMapExpand() {
      console.log("== onMapExpand ==");
      MainService.expandAppWindow();
    }
  },
};
</script>
<style scoped>
.wrapper {
  text-align: left;
  height: 100%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 0;
}

.wrapper :deep(.left) {
  display: flex;
  align-items: center;
}

:deep(.main) {
  padding: 0;
}

.header-container {
  display: flex;
  justify-items: center;
  align-items: flex-start;
}

.header-container .header-container-icon {
  margin-right: 15px;
}

.menu-container {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 60px;
  background-color: #32224E;
  padding-left: 22px;
}

.menu-container .label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  flex-grow: 1;
  text-align: center;
}

.menu-container #button-dropdown {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 10px;

  height: 32px;

  /* absolute/primary-2.0/P50 */

  background: #673FB2;
  /* absolute/primary-2.0/P40 */

  border: 1px solid #8A5ED5;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #B383FF, 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #F4EDFF;

  cursor: pointer;
}

.arrow-container {
  margin-left: 5px;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  color: #ffffff;

}

.up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin: 5px 7px 0 7px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: -2px 6px 4px 8px;
}


.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.subtitle {
  font-weight: 400;
  font-size: 8px;
  line-height: 20px;
}

.content {
  padding: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.bottom {
  /* position: absolute;
  bottom: 0; */
  background: #32224E;
  width: 100%;
  height: 62px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom> :deep(.begin-btn) {
  color: #D7C8F1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

hr {
  border-top: 1px solid var(--secondary-color);
}

.content :deep(.mapdiv) {
  /* border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px; */
}

.map-expand-button {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  position: absolute;
  top: 70px;
  right: 20px;
}

.map-expand-button-container {
  margin: 20px;
  padding: 20px;
}

.content-container {
  height: 100%;
}

/* ----------------------------
POI List
---------------------------- */
.content #poi-list {
  background-color: #3E2A62;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
}

.content #poi-list .schools-near-you {
  font-style: normal;
  font-size: 14px;
  color: #999999;
}

.wrapper :deep(.main) {
  height: 100%;
}

.wrapper :deep(.top) {
  width: 100%;
  z-index: 200;
  background: #32224E;
  margin-top: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 88px;
  margin-bottom: 0;
}

#button-show-menu {
  position: absolute;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}


.menu-content {
  background-color: #3E2A62;
  display: table;
  height: 200px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translatex(-50%);
  width: 90%;
  border-radius: 0 0 30px 30px;
  color: #ffffff;
  padding: 16px 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 200;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.menu-content ul {
  padding: 0;
}

.menu-content ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 8px 5px;
  list-style-type: none;
  cursor: pointer;
}

.menu-content ul li:before {
  content: "\25CF";
  font-size: 20px;
  line-height: 20px;
  color: #5D4B7F;
  padding-right: 20px;
  vertical-align: top;
}

.menu-content ul li.selected:before {
  color: #B383FF;
}

.menu-content-header {
  margin: 10px 20px 20px 20px;
}

.menu-content-header h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #ededed;
}

.menu-content-header div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ededed;
}

#modal-close {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 900;
}

#modal-close :deep(svg) {
  padding: 5px;
}

.marker-row-bottom {
  padding: 10px 0 5px 0;
}

/* ----------------------------
Categories
---------------------------- */
#categories-track {
  background-color: #32224E;
}

/* ----------------------------
Marker Row
---------------------------- */
.marker-row-container {
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px 24px;
}

.marker-row {
  border: 1px solid #D7C8F1;
  border-radius: 5px;
  padding: 16px;
  margin: 12px 0;
  cursor: pointer;
}

/* .marker-row-selected{
  background: #B383FF;
} */

.marker-row-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* margin-bottom: 8px; */
}

.marker-row-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.marker-row-detail-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.marker-row-detail-bottom> :deep(button) {
  height: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #d7c8f1;
  margin: 4px 3px;
}

.marker-row-rating {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}

.row-menu .menu-content-header {
  margin-right: 0;
  margin-left: 0;
}

.label-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #b383ff;
}

.marker-row-vicinity,
.marker-row-rating {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  color: #D7C8F1;
}

.info-window-content {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: var(--black);
}

.info-window-content .poi-name {
  font-size: 14px;
}

.info-window-content .poi-rating {
  font-weight: bold;
  ;
}

.info-window-content .poi-rating {
  /* font-size: 14px; */
}

.info-window-content img.trademark {
  max-height: 22px;
  margin-top:7px;
  width: auto;  
}

.marker-row-header {
  display: flex;
}

.marker-row-header img.trademark {
  max-height: 22px;
  margin-left: 15px;
  width: auto;
}

.info-window-content .poi-vicinity {
  font-weight: normal;
  margin-top: 7px;
  margin-bottom: 7px;
}

.info-window-content .poi-name {
  font-size: 13px;
}

.info-window-content img.rate {
  height: 34px;
  width: 34px;  
}
</style>
