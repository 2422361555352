import axios from 'axios';
import { MapFilters } from "@/components/Neighborhood/MapFilters";

class MapService {
    getSchools(communityId) {

        let data = {
            params: {
                "id": communityId
            }
        };

        return axios.get(
                `${process.env.VUE_APP_SERVER_ENDPOINT}/map/schools`, data
            ).then(function(response) {
                console.log('MapService::getSchools');
                // console.log(response)
                return response.data;
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    getbuttonsFilter() {
        return MapFilters;
    }
}

export default new MapService();