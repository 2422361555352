// import data from '../plugins/translations.json'

class TranslationService {
    async loadTranslations() {
        return [
            // Intro Modal Persona Select
            {
                'key': 'intro_modal_persona_select_button_privacy_policy',
                'en': 'Privacy Policy',
                'es': 'Política de Privacidad'
            },
            {
                'key': 'intro_modal_persona_select_button_browser_compatibility',
                'en': 'Browser Compatibility',
                'es': 'Compatibilidad del Navegador'
            },

            {
                'key': 'intro_modal_persona_select_language',
                'en': 'Select your Language',
                'es': 'Elige tu Idioma'
            },

            {
                'key': 'intro_modal_persona_select_button_next',
                'en': 'Next',
                'es': 'Siguiente'
            },

            {
                'key': 'intro_modal_persona_select_agent',
                'en': "Select your agent",
                'es': 'Seleccione su agente'
            },

            {
                'key': 'intro_modal_persona_select_button_begin',
                'en': 'Begin',
                'es': 'Comenzar'
            },

            {
                'key': 'intro_modal_persona_select_button_back',
                'en': 'Back',
                'es': 'Atrás'
            },

            {
                'key': 'intro_modal_persona_select_step',
                'en': 'OF',
                'es': 'DE'
            },

            // -----
            // -----
            {
                'key': 'intro_modal_persona_select_modal1_subtitle',
                'en': "Who would you like to talk to today?",
                'es': '¿Con quién te gustaría hablar hoy?'
            },
            {
                'key': 'intro_modal_persona_select_modal1_button1',
                'en': "Continue",
                'es': 'Continuar'
            },
            // -----
            {
                'key': 'intro_modal_persona_select_modal2_title_prefix',
                'en': "I'm",
                'es': 'Hola, soy'
            },
            {
                'key': 'intro_modal_persona_select_modal2_title_suffix',
                'en': "Nice to meet you.",
                'es': 'Es un gusto conocerte.'
            },
            {
                'key': 'intro_modal_persona_select_modal2_subtitle',
                'en': "Here are some things to know before we start chatting:",
                'es': 'Aquí hay algunas cosas que debes saber antes de comenzar a platicar:'
            },
            {
                'key': 'intro_modal_persona_select_modal2_paragraph1',
                'en': "I’m still learning so please be patient with me.",
                'es': 'Todavía estoy aprendiendo, así que ten paciencia conmigo.'
            },
            {
                'key': 'intro_modal_persona_select_modal2_paragraph2',
                'en': "Please turn on your camera and microphone.",
                'es': 'Por favor enciende tu cámara y tu micrófono.'
            },
            {
                'key': 'intro_modal_persona_select_modal2_paragraph3',
                'en': "Pick a nice, quiet spot for us to talk.",
                'es': 'Escoge un lugar agradable y silencioso para que podamos platicar.'
            },
            {
                'key': 'intro_modal_persona_select_modal2_paragraph4',
                'en': "Ask me questions about living at ",
                'es': 'Hazme preguntas sobre vivir en '
            },
            {
                'key': 'intro_modal_persona_select_modal2_button1',
                'en': "Change consultant",
                'es': 'Cambiar Consultor'
            },
            {
                'key': 'intro_modal_persona_select_modal2_button2',
                'en': "Continue",
                'es': 'Continuar'
            },
            {
                'key': 'intro_modal_persona_select_modal2_select_language',
                'en': "Select language",
                'es': 'Seleccionar idioma'
            },
            // Map
            {
                'key': 'map_button_back',
                'en': 'Back',
                'es': 'Atrás'
            },
            {
                'key': 'map_label_neighborhood_filter',
                'en': 'Neighborhood filter',
                'es': 'Filtro del vecindario'
            },

            {
                'key': 'map_category_school',
                'en': 'Schools',
                'es': 'Escuelas'
            },
            {
                'key': 'map_category_church',
                'en': 'Worship',
                'es': 'Templos'
            },
            {
                'key': 'map_category_shopping_mall',
                'en': 'Shopping',
                'es': 'Compras'
            },
            {
                'key': 'map_category_night_club',
                'en': 'Entertainment',
                'es': 'Entretenimiento'
            },
            {
                'key': 'map_category_restaurant',
                'en': 'Food',
                'es': 'Alimentos'
            },
            {
                'key': 'map_category_home_goods_store',
                'en': 'Recreation',
                'es': 'Recreación'
            },
            {
                'key': 'map_category_transit_station',
                'en': 'Transportation',
                'es': 'Transporte'
            },
            {
                'key': 'map_category_hospital',
                'en': 'Medical',
                'es': 'Médicos'
            },
            // Church
            {
                'key': 'map_subcategory_church',
                'en': 'Church',
                'es': 'Iglesia'
            },
            {
                'key': 'map_subcategory_hindu_temple',
                'en': 'Hindu Temple',
                'es': 'Templo Hindú'
            },
            {
                'key': 'map_subcategory_mosque',
                'en': 'Mosque',
                'es': 'Mezquita'
            },
            {
                'key': 'map_subcategory_synagogue',
                'en': 'Synagogue',
                'es': 'Sinagoga'
            },

            // Shopping
            {
                'key': 'map_subcategory_shopping_mall',
                'en': 'Shopping Mall',
                'es': 'Centro Comercial'
            },
            {
                'key': 'map_subcategory_clothing_store',
                'en': 'Clothing Store',
                'es': 'Tienda de Ropa'
            },
            {
                'key': 'map_subcategory_department_store',
                'en': 'Department Store',
                'es': 'Grandes Almacenes'
            },
            {
                'key': 'map_subcategory_electronics_store',
                'en': 'Electronics Store',
                'es': 'Tienda de Electrónicos'
            },
            {
                'key': 'map_subcategory_furniture_store',
                'en': 'Furniture Store',
                'es': 'Tienda de Muebles'
            },
            {
                'key': 'map_subcategory_home_goods_store',
                'en': 'Home Goods Store',
                'es': 'Tienda para el Hogar'
            },

            // Entertainment
            {
                'key': 'map_subcategory_movie_theater',
                'en': 'Movie Theater',
                'es': 'Cine'
            },
            {
                'key': 'map_subcategory_night_club',
                'en': 'Night Club',
                'es': 'Club Nocturno'
            },
            {
                'key': 'map_subcategory_amusement_park',
                'en': 'Amusement Park',
                'es': 'Parque de Atracciones'
            },
            {
                'key': 'map_subcategory_art_gallery',
                'en': 'Art Gallery',
                'es': 'Galería de Arte'
            },
            {
                'key': 'map_subcategory_casino',
                'en': 'Casino',
                'es': 'Casino'
            },
            {
                'key': 'map_subcategory_museum',
                'en': 'Museum',
                'es': 'Museo'
            },
            {
                'key': 'map_subcategory_stadium',
                'en': 'Stadium',
                'es': 'Estadio'
            },

            // Food
            {
                'key': 'map_subcategory_restaurant',
                'en': 'Restaurant',
                'es': 'Restaurante'
            },
            {
                'key': 'map_subcategory_bakery',
                'en': 'Bakery',
                'es': 'Panadería'
            },
            {
                'key': 'map_subcategory_supermarket',
                'en': 'Supermarket',
                'es': 'Supermercado'
            },
            {
                'key': 'map_subcategory_cafe',
                'en': 'Cafe',
                'es': 'Cafetería'
            },

            // Recreation
            {
                'key': 'map_subcategory_park',
                'en': 'Park',
                'es': 'Parque'
            },
            {
                'key': 'map_subcategory_zoo',
                'en': 'Zoo',
                'es': 'Zoológico'
            },
            {
                'key': 'map_subcategory_aquarium',
                'en': 'Aquarium',
                'es': 'Acuario'
            },
            {
                'key': 'map_subcategory_tourist_attraction',
                'en': 'Tourist Attraction',
                'es': 'Atracción Turística'
            },

            // Transportation
            {
                'key': 'map_subcategory_transit_station',
                'en': 'Transit Station',
                'es': 'Estación de Tránsito'
            },
            {
                'key': 'map_subcategory_airport',
                'en': 'Airport',
                'es': 'Aeropuerto'
            },
            {
                'key': 'map_subcategory_bus_station',
                'en': 'Bus Station',
                'es': 'Estación de Autobus'
            },
            {
                'key': 'map_subcategory_light_rail_station',
                'en': 'Light Rail Station',
                'es': 'Estación de Tren Ligero'
            },
            {
                'key': 'map_subcategory_subway_station',
                'en': 'Subway Station',
                'es': 'Estación de Metro'
            },
            {
                'key': 'map_subcategory_taxi_stand',
                'en': 'Taxi Stand',
                'es': 'Parada de Taxi'
            },
            {
                'key': 'map_subcategory_train_station',
                'en': 'Train Station',
                'es': 'Estación del Tren'
            },

            // Medical
            {
                'key': 'map_subcategory_hospital',
                'en': 'Hospital',
                'es': 'Hospital'
            },
            {
                'key': 'map_subcategory_doctor',
                'en': 'Doctor',
                'es': 'Doctor'
            },
            {
                'key': 'map_subcategory_physiotherapist',
                'en': 'Physiotherapist',
                'es': 'Fisioterapeuta'
            },
            {
                'key': 'map_subcategory_pharmacy',
                'en': 'Pharmacy',
                'es': 'Farmacia'
            },
            {
                'key': 'map_subcategory_veterinary_care',
                'en': 'Veterinary Care',
                'es': 'Cuidado Veterinario'
            },

            // ========================================================

            // ChatHistory
            {
                'key': 'chat_history',
                'en': 'Chat History',
                'es': 'Historial de Conversación'
            },
            {
                'key': 'chat_input_placeholder',
                'en': 'Write a message',
                'es': 'Escriba un mensaje'
            },
            {
                'key': 'chat_history_menu_top_card',
                'en': 'Menu',
                'es': 'Menú'
            },
            {
                'key': 'chat_history_menu_bottom_card',
                'en': 'Please, say or select one of the options above.',
                'es': 'Por favor, diga o seleccione una de las opciones anteriores.'
            },
            {
                'key': 'chat_history_label_quick_replies',
                'en': 'Quick Replies',
                'es': 'Respuestas rápidas'
            },
            {
                'key': 'chat_history_label_floorplans_available',
                'en': 'Floorplans Available',
                'es': 'Planos disponibles'
            },
            {
                'key': 'chat_history_label_floorplans_button_view',
                'en': 'View',
                'es': 'Ver'
            },
            {
                'key': 'chat_history_label_floorplans_label_starting_at',
                'en': 'Starting at',
                'es': 'A partir de'
            },
            {
                'key': 'chat_history_label_floorplans_label_call',
                'en': 'Call',
                'es': 'Llamar al'
            },
            {
                'key': 'chat_history_label_floorplans_label_more_information',
                'en': 'for more information',
                'es': 'para más información'
            },
            {
                'key': 'chat_history_label_calendar',
                'en': 'Calendar',
                'es': 'Calendario'
            },
            {
                'key': 'chat_history_label_calendar_bottom',
                'en': 'Please say or click on the date you want',
                'es': 'Por favor diga o haga clic en la fecha que desee'
            },
            {
                'key': 'chat_history_label_processing_request',
                'en': 'Processing your request',
                'es': 'Procesando su solicitud'
            },
            {
                'key': 'chat_history_label_button_send',
                'en': 'Send',
                'es': 'Enviar'
            },
            {
                'key': 'chat_history_label_selected_time',
                'en': 'Selected time',
                'es': 'Hora seleccionada'
            },
            {
                'key': 'chat_history_label_selected_date',
                'en': 'Selected date',
                'es': 'Fecha seleccionada'
            },

            // QuickReplies
            {
                'key': 'quick_replies_card_title',
                'en': 'What would you like to see?',
                'es': '¿Qué le gustaría ver?'
            },
            {
                'key': 'quick_replies_card_bottom',
                'en': 'Please, say or click on the option that you want.',
                'es': 'Por favor, diga o dé click en la opción que usted quiera'
            },
            {
                'key': 'quick_replies_label_selected_time',
                'en': 'Selected time',
                'es': 'Hora seleccionada'
            },

            // Floorplan List
            {
                'key': 'floorplan_list_card_title',
                'en': 'Floorplans Available',
                'es': 'Planos Disponibles'
            },
            {
                'key': 'floorplan_list_label_call',
                'en': 'Call',
                'es': 'Llamar al'
            },
            {
                'key': 'floorplan_list_label_more_information',
                'en': 'for more information',
                'es': 'para más información'
            },
            {
                'key': 'floorplan_list_label_starting_at',
                'en': 'Starting at',
                'es': 'A partir de'
            },
            {
                'key': 'floorplan_list_button_view_floorplan',
                'en': 'View floorplan',
                'es': 'Ver plano'
            },
            {
                'key': 'floorplan_list_label_per_month',
                'en': 'mo',
                'es': 'mes'
            },

            // Rating Modal
            {
                'key': 'rating_modal_headline',
                'en': 'Thanks for allowing me to help today. Would you mind telling me how I did?',
                'es': 'Gracias por permitirme ayudarte hoy. ¿Te importaría decirme cómo lo hice?'
            },
            {
                'key': 'rating_modal_label_rate_experience',
                'en': 'How would you rate your experience?',
                'es': '¿Cómo calificaría su experiencia?'
            },
            {
                'key': 'rating_modal_label_suggestions',
                'en': 'Do you have any suggestions or feedback?',
                'es': '¿Tiene alguna sugerencia o comentario?'
            },
            {
                'key': 'rating_modal_button_send_feedback',
                'en': 'Send Feedback',
                'es': 'Enviar comentarios'
            },
            {
                'key': 'rating_modal_button_skip',
                'en': 'Skip for now',
                'es': 'Saltar por ahora'
            },
            {
                'key': 'rating_modal_error_fields_not_empty',
                'en': 'Please, make sure that fields are not empty.',
                'es': 'Por favor, asegúrese de que los campos no estén vacíos.'
            },

            // VideoWindow
            {
                'key': 'video_window_exit',
                'en': 'Exit',
                'es': 'Salir'
            },
            {
                'key': 'video_window_mic',
                'en': 'Mic',
                'es': 'Mic'
            },
            {
                'key': 'video_window_speaker',
                'en': 'Speaker',
                'es': 'Altavoz'
            },
            {
                'key': 'video_window_schedule_tour',
                'en': 'Schedule a Tour',
                'es': 'Agendar una cita'
            },     
            {
                'key': 'input_text_search',
                'en': 'Type Your Response...',
                'es': 'Escribe Tu Respuesta...'
            },
            {
                'key': 'video_window_infotip',
                'en': 'Simply speak to the Digital Human to get the conversation started. You can also click/tap or type, the choice is yours!',
                'es': 'Simplemente hable con el humano digital para iniciar la conversación. También puede hacer click/tocar o escribir, ¡la elección es suya!'
            },
            {
                'key': 'video_window_chat_placeholder',
                'en': 'Ask a question...',
                'es': 'Has una pregunta...'
            },
            {
                'key': 'date_top_left_tour',
                'en': 'Select Date',
                'es': 'Selecciona Fecha'
            },
            {
                'key': 'time_top_left_tour',
                'en': 'Select Time',
                'es': 'Selecciona Tiempo'
            },
            {
                'key': 'form_one_left_schedule_tour',
                'en': 'We need some basic information',
                'es': 'Necesitamos algunos datos basicos'
            },
            {
                'key': 'form_one_tittle_schedule_tour',
                'en': 'Schedule a Tour',
                'es': 'Agenda un Tour'
            },
            {
                'key': 'form_one_subtitle_schedule_tour',
                'en': 'What are you looking for?',
                'es': '¿Qué estás buscando?'
            },
            {
                'key': 'date_top_right_tour',
                'en': 'Choose a date',
                'es': 'Selecciona una fecha'
            },
            {
                'key': 'time_not_found',
                'en': 'Not availble times',
                'es': 'No tiempos disponibles'
            },
            {
                'key': 'time_top_right_tour',
                'en': 'Choose a time',
                'es': 'Selecciona una hora'
            },
            // Menu
            {
                'key': 'menu_headline',
                'en': 'What Do You Want To Explore?',
                'es': '¿Qué quieres explorar?'
            },

            // Calendar Card
            {
                'key': 'calendar_card_calendar',
                'en': 'Calendar',
                'es': 'Calendario'
            },
            {
                'key': 'calendar_card_bottom',
                'en': 'Please say or click on the date you want.',
                'es': 'Por favor, diga o dé click en la fecha que usted quiera.'
            },
            {
                'key': 'calendar_card_label_selected_date',
                'en': 'Selected date',
                'es': 'Fecha Seleccionada'
            },
            
            // Ask a Question Form
            {
                'key': 'ask_question_title_message',
                'en': 'Message Sent Successfully!',
                'es': '¡Mensaje enviado exitosamente!'
            },
            {
                'key': 'ask_question_subtitle_message',
                'en': 'We will response as soon as we can, typically within 24 hours.',
                'es': 'Responderemos tan pronto como podamos, generalmente dentro de las 24 horas.'
            },

            // Appointment Information Form
            {
                'key': 'appointment_information_form_header',
                'en': 'Appointment Information Form',
                'es': 'Formulario de Información de Citas'
            },
            {
                'key': 'appointment_information_form_input_given_name',
                'en': 'Given Name (Required)',
                'es': 'Nombre de Pila (Requerido)'
            },
            {
                'key': 'appointment_information_form_label_given_name',
                'en': 'Given Name is required',
                'es': 'Nombre de Pila es requerido'
            },
            {
                'key': 'appointment_information_form_input_last_name',
                'en': 'Last Name (Required)',
                'es': 'Apellido (Requerido)'
            },
            {
                'key': 'appointment_information_form_label_last_name',
                'en': 'Last Name is required',
                'es': 'El Apellido es requerido'
            },
            {
                'key': 'appointment_information_form_input_email',
                'en': 'Your email (Required)',
                'es': 'Su correo (Requerido)'
            },
            {
                'key': 'appointment_information_form_label_email',
                'en': 'Email is required',
                'es': 'Correo es requerido'
            },
            {
                'key': 'appointment_information_form_label2_email',
                'en': 'Invalid email',
                'es': 'Correo inválido'
            },
            {
                'key': 'appointment_information_form_input_phone',
                'en': 'Your phone number (optional)',
                'es': 'Su número de teléfono (opcional)'
            },
            {
                'key': 'appointment_information_form_label_phone',
                'en': '10 digit format',
                'es': 'Formato de 10 dígitos'
            },
            // Schedule Tour Form
            {
                'key': 'schedule_tour_form_label_desired_number_bedrooms',
                'en': 'Bedrooms',
                'es': 'Dormitorios'
            },
            {
                'key': 'schedule_tour_form_label_max_budget',
                'en': 'Price Range',
                'es': 'Presupuesto Máximo'
            },
            {
                'key': 'floorplans_form_label_square_feet',
                'en': 'Square Feet (Optional)',
                'es': 'Metros Cuadrados (Opcional)'
            },
            {
                'key': 'schedule_tour_form_label_move_in_date',
                'en': 'Move-in date (Optional)',
                'es': 'Fecha de mudanza (Opcional)'
            },
            {
                'key': 'schedule_tour_form_subtitle_fill_out_this_form',
                'en': 'A few items to get things started',
                'es': 'Algunos datos para empezar'
            },
            {
                'key': 'schedule_tour_title_basic_info',
                'en': 'Basic info',
                'es': 'Información básica'
            },
            {
                'key': 'schedule_tour_title_tour_date', 
                'en': 'Tour Date',
                'es': 'Fecha del Tour'
            },
            {
                'key': 'schedule_tour_label_first_name',
                'en': 'First Name',
                'es': 'Nombre'
            },
            {
                'key': 'schedule_tour_label_last_name',
                'en': 'Last Name',
                'es': 'Apellido(s)'
            },
            {
                'key': 'schedule_tour_label_email',
                'en': 'Email',
                'es': 'Correo electrónico'
            },
            {
                'key': 'schedule_tour_label_phone',
                'en': 'Phone Number (Optional)',
                'es': 'Teléfono (Opcional)'
            },
            {
                'key': 'schedule_tour_required',
                'en': 'required*',
                'es': 'requerido*'
            },
            {
                'key': 'schedule_tour_required_field',
                'en': 'This field is required',
                'es': 'Este campo es requerido'
            },
            {
                'key': 'schedule_tour_invalid_email',
                'en': 'This email is invalid',
                'es': 'Este email es inválido'
            },
            {
                'key': 'schedule_tour_invalid_phone',
                'en': 'Invalid phone',
                'es': 'Teléfono inválido'
            },
            {
                'key': 'schedule_tour_placeholder_name',
                'en': 'Enter your name',
                'es': 'Introduzca su nombre'
            },
            {
                'key': 'schedule_tour_placeholder_last_name',
                'en': 'Enter your last name',
                'es': 'Introduzca su apellido'
            },
            {
                'key': 'schedule_tour_placeholder_email',
                'en': 'Enter your email',
                'es': 'Introduce tu correo electrónico'
            },
            {
                'key': 'schedule_tour_placeholder_phone',
                'en': 'Enter your phone',
                'es': 'Introduce tu teléfono'
            },
            {
                'key': 'schedule_tour_studio',
                'en': 'Studio',
                'es': 'Estudio'
            },
            // Schedule Tour review screen
            {
                'key': 'schedule_tour_review_subtitle_confirm',
                'en': 'Confirm',
                'es': 'Confirmar'
            },
            {
                'key': 'schedule_tour_review_title_review_details',
                'en': 'Review Details',
                'es': 'Revisar detalles'
            },
            {
                'key': 'schedule_tour_review_title_appointment_summary',
                'en': 'Submit the information below',
                'es': 'Envíe la información a continuación'
            },
            {
                'key': 'schedule_tour_review_label_date_time',
                'en': 'Scheduled Time',
                'es': 'Hora programada'
            },
            {
                'key': 'schedule_tour_review_label_location',
                'en': 'Location',
                'es': 'Ubicación'
            },
            {
                'key': 'schedule_tour_review_label_yes',
                'en': 'Yes',
                'es': 'Si'
            },
            {
                'key': 'schedule_tour_review_label_movein_date',
                'en': 'Move-in Date',
                'es': 'Fecha de mudanza'
            },
            {
                'key': 'schedule_tour_review_label_contact_details',
                'en': 'Contact Details',
                'es': 'Detalles de contacto'
            },
            {
                'key': 'schedule_tour_review_label_preferences',
                'en': 'Bedroom Preference',
                'es': 'Preferencia de dormitorio'
            },
            {
                'key': 'schedule_tour_review_label_price_range',
                'en': 'Price Range',
                'es': 'Presupuesto Máximo'
            },
            {
                'key': 'schedule_tour_review_label_feet_range',
                'en': 'Feet Range',
                'es': 'Metros Cuadrados'
            },
            {
                'key': 'schedule_tour_review_label_pets',
                'en': 'Pets',
                'es': 'Mascotas'
            },
            {
                'key': 'schedule_tour_review_label_contact_method',
                'en': 'Contact Method',
                'es': 'Metodo de Contacto'
            },
            {
                'key': 'schedule_tour_review_label_email',
                'en': 'Email',
                'es': 'Correo'
            },
            // Schedule Tour feedback and thank you screens
            {
                'key': 'schedule_tour_review_title_feedback',
                'en': 'Feedback',
                'es': 'Comentarios'
            },
            {
                'key': 'schedule_tour_review_subtitle_feedback',
                'en': 'Add your comments below',
                'es': 'Añade tus comentarios abajo'
            },
            {
                'key': 'schedule_tour_review_title_appointment_confirmation',
                'en': 'Appointment Confirmed!',
                'es': '¡Cita confirmada!'
            },
            {
                'key': 'schedule_tour_review_title_appointment_review',
                'en': 'How would you rate your experience?',
                'es': '¿Cómo calificaría su experiencia?'
            },
            {
                'key': 'schedule_tour_review_title_thankyou',
                'en': 'Thank You for your Feedback!',
                'es': '¡Gracias por tu Feedback!'
            },
            // ---
            {
                'key': 'appointment_information_form_label_notes',
                'en': 'Notes (Optional)',
                'es': 'Notas (Opcional)'
            },
            {
                'key': 'appointment_information_form_input_notes',
                'en': 'Please include any request or comments for our leasing team',
                'es': 'Por favor incluya cualquier solicitud o comentario para nuestro equipo de arrendamiento'
            },
            // ---
            {
                'key': 'appointment_information_form_label_appointment_scheduled',
                'en': 'Your appointment is scheduled for',
                'es': 'Su cita está programada para'
            },
            {
                'key': 'appointment_information_form_label_at',
                'en': 'at',
                'es': 'a las'
            },
            // ---
            {
                'key': 'contact_form_input_given_name',
                'en': 'Given Name',
                'es': 'Nombre'
            },
            {
                'key': 'contact_form_input_last_name',
                'en': 'Last Name',
                'es': 'Apellido'
            },
            {
                'key': 'contact_form_input_email',
                'en': 'Email',
                'es': 'Correo electrónico'
            },
            {
                'key': 'contact_form_input_phone',
                'en': 'Phone number',
                'es': 'Número de teléfono'
            },
            {
                'key': 'contact_form_input_notes',
                'en': 'What can we do for you?',
                'es': '¿En que te podemos ayudar?'
            },
            {
                'key': 'contact_form_form_label_cancel_button',
                'en': 'Skip',
                'es': 'Omitir'
            },
            {
                'key': 'contact_form_form_label_submit_button',
                'en': 'Save Contact',
                'es': 'Guardar Contacto'
            },
            {
                'key': 'contact_form_label_required',
                'en': 'Required',
                'es': 'Requerido'
            },
            {
                'key': 'contact_form_label_format',
                'en': 'Valid email is required',
                'es': 'Se requiere un email válido'
            },
            {
                'key': 'contact_form_label_phone_required',
                'en': 'Must be 10 digit format',
                'es': 'Debe ser en formato de 10 dígitos'
            },
            {
                'key': 'contact_form_bottom_card',
                'en': 'Fill the form and click save to send the information.',
                'es': 'Llene el formulario y presione salvar para enviar la información.'
            },
            {
                'key': 'contact_form_top_card',
                'en': 'Contact',
                'es': 'Contacto'
            },
            {
                'key': 'contact_form_success',
                'en': 'Success! Form successfully submitted',
                'es': 'Exito! Formulario enviado de forma exitosa'
            },
            {
                'key': 'contact_form_falied',
                'en': 'Error! Form submission failed',
                'es': 'Error! Envio de formulario fallo'
            },
            // ---
            {
                'key': 'appointment_information_form_label_confirm_appointment_button',
                'en': 'Confirm Appointment',
                'es': 'Confirmar cita'
            },
            {
                'key': 'appointment_information_form_label_confirm_appointment_loading',
                'en': 'Loading',
                'es': 'Cargando'
            },
            // ---
            {
                'key': 'appointment_information_form_label_cannot_process',
                'en': 'We cannot process your request. Please contact us using the information below.',
                'es': 'No podemos procesar su solicitud. Comuníquese con nosotros utilizando la siguiente información.'
            },
            {
                'key': 'appointment_information_form_label_contact_leasing_directly',
                'en': 'You can contact the leasing team directly at',
                'es': 'Puede ponerse en contacto con el equipo de arrendamiento directamente al'
            },

            // Appointment Information Form
            {
                'key': 'tour_service_label_successfully_sent',
                'en': 'Appointment form Successfully sent',
                'es': 'Formulario de citas fue enviado exitosamente'
            },
            // Contact Form
            {
                'key': 'contact_form_label_successfully_sent',
                'en': 'Contact form Successfully sent',
                'es': 'Formulario de contacto fue enviado exitosamente'
            },
            // Appointment Information Form
            {
                'key': 'contact_form_label_cancel_sent',
                'en': 'Cancel',
                'es': 'Cancelar'
            },
            // Modal buttons
            {
                'key': 'button_add_feedback',
                'en': 'add feedback',
                'es': 'comentarios'
            },
            {
                'key': 'button_next',
                'en': 'next',
                'es': 'siguiente'
            },
            {
                'key': 'button_cancel',
                'en': 'cancel',
                'es': 'cancelar'
            },
            {
                'key': 'button_back',
                'en': 'back',
                'es': 'atrás'
            },
            {
                'key': 'button_previous',
                'en': 'back',
                'es': 'anterior'
            },
            {
                'key': 'button_review',
                'en': 'review',
                'es': 'revisar'
            },
            {
                'key': 'button_submit', 
                'en': 'submit',
                'es': 'enviar'
            },
            {
                'key': 'button_select', 
                'en': 'select',
                'es': 'seleccionar'
            },
            {
                'key': 'button_done', 
                'en': 'done',
                'es': 'terminar'
            },
            {
                'key': 'button_details', 
                'en': 'add details',
                'es': 'añadir detalles'
            },
            {
                'key': 'button_add_to_calendar',
                'en': 'add to calendar',
                'es': 'calendario'
            },
            {
                'key': 'button_edit_search',
                'en': 'edit search',
                'es': 'editar búsqueda'
            },
            // Schedule Tour Form
            {
                'key': 'Desired',
                'en': 'next',
                'es': 'siguiente'
            },
            {
                'key': 'of',
                'en': 'of',
                'es': 'de'
            },
            {
                'key': 'schedule_tour_form_skip',
                'en': 'skip',
                'es': 'saltar'
            },
        ];
    }
}
export default new TranslationService()