<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="feedbackComponent">
        <div class="feedback-content">
          <div class="check-container">
            <check-icon />
          </div>
          <label class="title">{{
            getTranslation(
              'schedule_tour_review_title_appointment_confirmation'
            )
          }}</label>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalLayout from '@/components/Shared/ModalLayout.vue'
import CheckIcon from '@/components/Shared/CheckIcon.vue'

export default {
  name: 'ScheduleTourConfirm',
  components: {
    ModalLayout,
    CheckIcon,
  },

  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
    }),
  },
}
</script>

<style scoped>
.feedback-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}
.feedbackComponent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: var(--white);
  margin-top: 20px;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tertiary-color);
  margin-top: 20px;
}

.bottom {
  margin-top: 20px;
  margin-left: 20px;
}

.feather {
  stroke-width: 1px;
  width: 60px;
  height: 60px;
  margin: 14px;
}

.up-icon:hover svg,
.up-icon.active svg {
  fill: var(--secondary-color);
  transform: scale(1.2);
  animation: pulse 2s;
}

.down-icon:hover svg,
.down-icon.active svg {
  fill: var(--secondary-color);
  transform: scale(1.2);
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
