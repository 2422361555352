<template>
  <div 
    v-if="this.conversations" 
    class="wrapper-chat-history" 
  >
    <template 
      v-for="(conversation, index) in this.conversations" 
      :key="index"
    >
      <ChatBubble 
        v-if="conversation.intentName != 'Welcome'"
        class="mb-2"
        :text="conversation.input"
        side="right"
      />

      <ChatBubble 
        class="mb-2"
        :text="conversation.output"
        side="left"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ChatBubble from "@/components/Chat/ChatBubble.vue";

export default {
  name: 'ChatHistory',

  components: {
    ChatBubble,
  },

  data() {
    return {
    }
  },

  computed:{
    ...mapGetters({
      getConversations: "conversation/getConversations",
      getLastConversation: "conversation/getLastConversation",
    }),

    // 
    conversations(){
      return this.getConversations;
    },

    // Last conversation added to the array
    lastConversation(){
      return this.getLastConversation;
    },
  },

  watch: {

    // Keeps watching the new conversations in order to check if the conversation
    // has changes for the menu
    lastConversation(){

      // We use next tick in order to wait the DOM to be 
      // updated
      this.$nextTick(() => {
        this.$el.scrollTop = this.$el.scrollHeight;
      });
    }
  },

  mounted(){
  }
}
</script>
<style scoped>
.wrapper-chat-history{
  overflow-y: scroll;
}
</style>
