<template>
  
  <div class="wrapper">

    <div class="expander">
      <div id="expander-icon" :class="isMenuExpanded ? 'expander-rotate' : ''">
        <ButtonIcon 
          id="button-expander-icon"
          symbol="align-justify"
          @onClick="handleButtonClick"
        />
      </div>
    </div>

    <XyzTransition appear xyz="fade up-0% origin-top flip-down">
      <div 
        v-show="isMenuExpanded"
        class="content" 
      >
        <div class="d-flex justify-content-between p-2 align-items-center">
          <div class="title"> {{ this.getTranslation('chat_history_menu_top_card') }} </div>
          <div class="description">{{ this.getTranslation('menu_headline') }}</div>
        </div>
        <div class="row">
          <template 
            v-for="(item, index) in this.items.slice(0, maxNumberOfItems)"
            :key="index"
          >
          <div :class="((index >= (this.items.length - 1)) && (index % 2 == 0)) ? 'px-2 pb-2 col': 'px-2 pb-2 col col-6'">
            <Button 
              @on-click="onButtonClick(item)"
              :text="item"
            />
          </div>
          </template>
        </div>  
      </div>
    </XyzTransition>
  </div>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ButtonIcon from '../Shared/ButtonIcon.vue'
import Button from "@/components/Shared/Button.vue";

export default {
  name: 'MenuIndex',

  components: {
    ButtonIcon,
    Button
  },

  props: {
    buttons: Array,
  },

  data() {
    return {
      items: [],
      maxNumberOfItems: 6
    }
  },

  computed:{
    ...mapGetters({
      getLastConversation: "conversation/getLastConversation",
      getPersona: "sdk/getPersona",
      getTranslation: "translation/getTranslation",
      getToggleValue: "menu/getToggleValue"
    }),

    // Last conversation added to the array
    lastConversation(){
      return this.getLastConversation;
    },

    persona(){
      return this.getPersona;
    },
    isMenuExpanded() { 
      return this.getToggleValue;
    }
  },

  watch: {

    // Keeps watching the new conversations in order to check if the conversation
    // has changes for the menu
    lastConversation(newConversation){
      if(newConversation && newConversation.fulfillmentMessages.length){
        this.checkUpdatesInMenu(newConversation.fulfillmentMessages);
        if (newConversation.intentName.includes('Amenities')) {
          this.toggleExpandMenu(true);
        }
      }
    },
  },

  created() {
      console.log("Menu Index created");
  },

  mounted() {
    console.log("Menu Index mounted");
  },
  
  methods: {
    ...mapActions({
      toggleExpandMenu: "menu/toggleExpandMenu"
    }),

    handleButtonClick() {
      this.toggleExpandMenu(!this.isMenuExpanded);
    },

    async onButtonClick(item){
      console.log("onButtonClick", item);

      if(this.persona && item){
        this.persona.conversationSend(item)
          .then()
          .catch((error) => console.log("Request Error", error));
      }
    },

    checkUpdatesInMenu(fulfillmentMessages){

      // Searches in the fulfillmentMessages array in order to find 
      // the value '{"type":"basic"}' that represents the udpate in the menu
      let fulfillmentMessage = fulfillmentMessages.find(function (message){
        return (message.quickReplies && message.quickReplies.quickReplies[0] == '{"type":"basic"}');
      });

      // Checks if the search found the required message
      if(fulfillmentMessage){

        // Gets the reply
        let reply = fulfillmentMessage.quickReplies.quickReplies;

        // Checks first exists
        if(reply[0]){

          // Parses the first element of the reply
          const replyType =  JSON.parse(reply[0]);

          // Checks if after the parse it is not undefined and
          // the type is "basic"
          if(replyType.type && replyType.type  == "basic" ){
            
            // Removes basic string ({"type":"basic"})
            reply.shift();

            // Updates the menu with the new changes.
            this.items = reply;
          }
        }
      }
    }
  },
}
</script>

<style scoped>
  .wrapper{
    border-top: 4px solid var(--primary-color);
    position: relative;
  }

  .expander{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  #expander-icon{
    transition: transform 0.2s ease-in-out;
    
  }

  #button-expander-icon :deep(svg) {
    border: 2px solid var(--primary-color);
    color: var(--white);
  }   

  .expander-rotate{
    transform: rotate(90deg);
  }

  .content{
    background-color: var(--primary-color);
    display: table;
    height: 100%;
    position: absolute;
    width: 100%;
    border-radius: 0 0 30px 30px;
    color: var(--white);
    padding: 16px 24px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }

  .title{
    font-size: 16px;
    color: var(--tertiary-color);
    font-weight: 700;
  }
  .description{
    font-size: 10px;
    color: var(--tertiary-color);
    font-weight: 700;
  }

  .begin-btn {
    color: var(--white);
    font-size: 15px;
    height: 50px;
  }
</style>