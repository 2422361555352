<template>
  <div class="wrapper-video-window">
    <div id="button-schedule-tour">
      <Button 
        style="z-index: 50; padding: 0 1rem;"
        @on-click="onButtonScheduleATourClick"
        :text="getTranslation('video_window_schedule_tour')" 
      />
    </div>

    <div id="button-info">
      <InfoTip 
        :text="getTranslation('video_window_infotip')" 
        direction="right"/>
    </div>

    <div id="button-back">
      <ButtonIcon 
        symbol="x"
        @onClick="closeAppWindow"
      />
    </div>
    <video
      ref="remoteVideo"
      playsinline
      autoplay
    />
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Shared/Button.vue';
import ButtonIcon from '@/components/Shared/ButtonIcon.vue'
import feather from 'feather-icons'
import InfoTip from '@/components/Shared/InfoTip.vue';
import MainService from '@/services/main.service'

export default {
  name: 'VideoWindowIndex',

  components: {
    Button,
    ButtonIcon,
    InfoTip
  },

  computed:{
    ...mapGetters({
      getPersona: "sdk/getPersona",
      getTranslation: "translation/getTranslation",
    }),

    persona(){
      return this.getPersona;
    }
  },

  async mounted(){
    this.setRemoteVideo(this.$refs.remoteVideo);
    feather.replace();
  },

  methods: {
    ...mapActions({
      setRemoteVideo: "sdk/setRemoteVideo",
      // setModal: "main/setModal",
    }),

    async onButtonScheduleATourClick(){
      console.log("onButtonScheduleATourClick");
      
      if(this.persona){
        this.persona.conversationSend("Schedule A Tour")
          .then()
          .catch((error) => console.log("Request Error", error));
      }

      // this.setModal("ScheduleTour");
    },

    async closeAppWindow(){
      MainService.closeAppWindow();
    }
  }
}
</script>

<style scoped>
.wrapper-video-window{
  position: relative;
}

.wrapper-video-window #button-schedule-tour{
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 100;
}

.wrapper-video-window #button-back{
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 100;
}
.wrapper-video-window #button-info{
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;
}

video {
  width:100%;
  height:100%;
  object-fit:cover;
  z-index: 0;
}
</style>