<template>
    <ModalLayout>
        <template v-slot:title>
        available dates
        </template>
        <template v-slot:main>
          <div class="date-options">
            <Dropdown
              :options="dateRanges"
              :value="selectedDateText"
              placeholder="14 days"
              @input="onDropdownChange"
            />
            <button class="pick-date-btn" @click="showCalendar">pick date <i data-feather="chevron-right" color="white"  width="26px" height="14px"></i></button>
          </div>
          <div class="calendar-buttons">
            <Button
              v-for="date in calendarDates" 
              :key="date.toString()" 
              :text="formatDate(date)"
              @click="onDateClick(date)"
              class="begin-btn"
              :style="dateButtonStyle(date)"
            />
          </div>
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from '../Shared/ModalLayout.vue';
import Dropdown from '../Shared/DropDown.vue'; 
import Button from '../Shared/Button.vue'; 
import feather from 'feather-icons'

export default {
  components: {
    ModalLayout,
    Dropdown,
    Button
  },
  data() {
    return {
      dateRanges: [
        { value: 3, label: '3 days' },
        { value: 14, label: '14 days' },
        { value: 30, label: '30 days' }
      ],
      selectedDateText: { value: 14, label: '14 days' }, 
      calendarDates: this.generateCalendarDates(14),
      selectedDate: null
    }
  },
  computed: {
    dateButtonStyle() {
    return (date) => ({
      backgroundColor: this.selectedDate === date ? 'rgb(190 155 255)' : 'var(--tertiary-color)',
      color: this.selectedDate === date ? 'rgb(60 50 78)' : '#47336b',
    });
  },
  },
  async mounted() {
    feather.replace()
  },
  methods: {
    showCalendar() {
      this.$emit('pick-date-clicked');
    },
    onDropdownChange(selectedOption) {
      this.selectedDateText = selectedOption;
      this.calendarDates = this.generateCalendarDates(selectedOption.value);
    },
    generateCalendarDates(days) {
      const dates = [];
      const today = new Date();
      for (let i = 0; dates.length < days; i++) {
        const currentDate = new Date(today.getTime() + (i * 24 * 60 * 60 * 1000));
        if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
          dates.push(currentDate);
        }
      }
      return dates;
    },
    formatDate(date) {
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    onDateClick(date) { 
      this.selectedDate = date;
      const datePicked = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      const userRequest = { userText: datePicked, inputType: 'clicked' }
      this.$emit('update:value', this.date)
      this.$emit('dateSelected', userRequest)
      console.log("Capturing anything?", userRequest);
    }
  }
}
</script>

<style scoped>
.date-options {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.pick-date-btn {
  background-color: transparent;
  border: none;
  color: white; 
  cursor: pointer;
  font-size: 15px;
  margin-right: 10px;
  padding: 20px;
}

.calendar-buttons {
  max-width: calc(100% - 25px); 
  max-height: 306px; 
  overflow-y: auto; 
  padding: 0 20px 0 10px;
  overflow-x: hidden;
}
.calendar-buttons > * {
  margin: 10px 20px;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px;
}
.begin-btn {
  font-size: 15px;
}
</style>