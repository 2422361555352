<template>
  <XyzTransition xyz="fade">
    <div v-if="initialized" :class="bubbleClass">
      <div class="avatar"></div>
      <div class="content">
        {{ text }}
      </div>
    </div>
  </XyzTransition>
</template>

<script>
export default {
  name: 'ChatBubble',
  props: {
    side: {
      default: 'left',
      type: String
    },
    text: String,
  },
  data() {
    return {
      bubbleClass: '',
      initialized: false
    }
  },

  created(){
    if(this.side == 'right'){ 
      this.bubbleClass = "chat-bubble side-right d-flex justify-content-start p-1 align-items-left flex-row-reverse";
    }else{
      this.bubbleClass = "chat-bubble side-left d-flex justify-content-start p-1 align-items-left";
    }
  },

  mounted(){
    this.initialized = true;
  }
}
</script>
<style scoped>

.chat-bubble {
  margin: 0 10px;
}

.chat-bubble .avatar{
  height: 25px;
  width: 28px;
  border-radius: 50%;
  display: inline-block;
  background-color: #cccccc;
  
}

.chat-bubble.side-left .avatar{
  margin-right: 10px;
}

.chat-bubble.side-right .avatar{
  margin-left: 10px;
}

.chat-bubble .content{
  padding: 14px 20px;
  border: 1px solid #cccccc;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
}

.chat-bubble.side-left .content{
  border-radius: 0 25px 25px 25px;
}

.chat-bubble.side-right .content{
  border-radius: 25px 0 25px 25px;
}
</style>
