<template>
  <div class="input-container">
    <label :for="inputId" class="input-label">{{ label }}</label>
    <textarea
      :id="inputId"
      :placeholder="placeholder"
      class="input-field"
      :style="{
        border: error
          ? '1px solid var(--error)'
          : '1px solid var(--secondary-color)',
      }"
      :value="value"
      @input="onInput($event)"
      :maxlength="max"
    ></textarea>
    <label
      class="required space"
      v-if="required"
      :style="{ color: error ? 'var(--error)' : 'var(--tertiary-color)' }"
      >{{ error ? 'this field is required' : 'required*' }}</label
    >
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputId() {
      return this.id || `textarea-${this._uid}`
    },
  },
  methods: {
    onInput(event) {
      this.$emit('update:value', event.target.value)
    },
  },
}
</script>
<style scoped>
.input-container {
  position: relative;
}
.required {
  width: 266px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
}

.input-label {
  position: absolute;
  top: -10px;
  left: 10px;
  color: var(--tertiary-color);
  background-color: var(--primary-color);
  padding: 0 5px;
  text-decoration-color: var(--white);
}

.input-field {
  font-size: 16px;
  padding: 10px;
  background-color: transparent;
  border-radius: 4px;
  color: var(--white);
  color-scheme: var(--secondary-color);
  resize: none;
  height: 48vh;
}

.input-field::placeholder {
  color: #ededed;
}

.input-field:active {
  border: 1px solid var(--tertiary-color) !important;
  outline: none;
  outline-color: transparent;
  outline-style: none;
}

.input-field:focus {
  border: 1px solid var(--tertiary-color) !important;
  outline: none;
  outline-color: transparent;
  outline-style: none;
}
</style>
