<template>
  <div class="modals-container">
    <!-- Home -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 0">
        <ScheduleTourHome
          @selfGuidedSelected="goToSelfGuidedTour"
          @inPersonSelected="goToInPersonTour"
        />
      </div>
    </XyzTransition>

    <!-- Self-Guided Tour -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 1">
        <ScheduleTourSelfGuided 
          @closeTourSelected="closeModal"
        />
      </div>
    </XyzTransition>

    <!-- Date -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 2">
        <ScheduleTourAvailabilityDates @pick-date-clicked="goToCalendar" @dateSelected="setDate" :value="this.date" />
        <container-buttons
          :textLeft="getTranslation('button_cancel')"
          :onClickLeft="() => this.closeModal()"
          :onClickRight="() => this.date && this.updateStep(4)"
          :textRight="getTranslation('button_next')"
        />
      </div>
    </XyzTransition>

    <!-- ScheduleTourCalendar -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 3">
        <ScheduleTourCalendar @dateSelected="setDate" :value="this.date" />
        <container-buttons
          :textLeft="getTranslation('button_cancel')"
          :onClickLeft="() => this.closeModal()"
          :onClickRight="() => this.date && this.updateStep(4)"
          :textRight="getTranslation('button_next')"
        />
      </div>
    </XyzTransition>

    <!-- Time -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 4">
        <ScheduleTourTime
          @timeSelected="setTime"
          :date="this.date"
          :time="this.time"
        />
        <container-buttons
          :textLeft="getTranslation('button_back')"
          :onClickLeft="() => this.updateStep(previousStep == 3 ? 3 : 2)"
          :onClickRight="() => this.time && this.updateStep(5)"
          :textRight="getTranslation('button_next')"
        />
      </div>
    </XyzTransition>

    <!-- Form -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 5">
        <ScheduleTourFormTwo
          :onClickPrev="() => (this.currentStep = 4)"
          :onClickReview="() => (this.currentStep = 6)"
          :form="this.tourForm"
          @update-form="updateTourForm"
          :date="this.dateTime"
        />
      </div>
    </XyzTransition>

    <!--Form Step 1-->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 6">
        <ScheduleDate
          @dateSelected="setMoveInDate" 
          :value="this.moveInDate"
          title="When do you want to move in?"
        />
        <container-buttons 
          :textLeft="getTranslation('button_back')" 
          :onClickLeft="() => this.updateStep(5)" 
          :onClickRight="() => this.updateStep(7)" 
          :textRight="getTranslation('button_next')"
        />
      </div>
    </XyzTransition>

    <!-- Form Step 2 -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 7">
        <ScheduleTourFormOne
          :onClickPrev="() => (this.currentStep = 6)"
          :onClickNext="() => (this.currentStep = 8)"
          :form="this.tourForm"
          @update-form="updateTourForm"
          :date="this.date"
        />
      </div>
    </XyzTransition>

    <!-- Review -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 8">
        <ScheduleTourReview
          :form="{ ...this.tourForm, date: this.date, time: this.time, moveInDate: this.moveInDate }"
          :date="this.dateTime"
          :onClickPrev="() => (this.currentStep = 7)"
          :onClickNext="() => (this.currentStep = 9)"
        />
      </div>
    </XyzTransition>

    <!-- Feedback -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 9">
        <ScheduleTourConfirm />
        <div class="subModal" v-if="subModal" ref="subModal">
          <add-to-calendar-button
            :name="`${this.getTranslation('video_window_schedule_tour')} - ${
              this.community.c_community_name
            }`"
            :description="`Meeting with: ${this.community.c_community_name}, ${this.community.c_email}, (${this.community.c_phone_number})`"
            :startDate="this.calendarDate.startDate"
            :startTime="this.calendarDate.startTime"
            :endTime="this.calendarDate.endTime"
            timeZone="US/Central"
            :location="`${this.community.c_address.trim()}, ${
              this.community.c_city
            } ${this.community.c_state}, ${this.community.c_postal_code}`"
            options="'Apple','Google','Outlook.com'"
            listStyle="modal"
            buttonStyle="round"
            trigger="click"
            buttonsList
            hideBackground
            lightMode="dark"
            size="5"
            styleDark="--btn-background: #3e2a62; --btn-border: #9787b5; --btn-background-hover: #3e2a62;"
          />
        </div>
        <container-buttons
          :textLeft="getTranslation('button_add_feedback')"
          :onClickLeft="() => this.updateStep(10)"
          :onClickRight="showSubModal"
          :textRight="getTranslation('button_add_to_calendar')"
        />
      </div>
    </XyzTransition>

    <!-- rate -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 10">
        <ScheduleTourRate 
          :onClickNext="() => (this.currentStep = 11)"
          @rate-clicked="this.nohideButtons = true" 
         />
         <container-buttons
          :textCenter="getTranslation('button_details')"
          :onClickCenter="() => (this.currentStep = 11)"
        />
        <ScheduleTourConfirm />
        
      </div>
    </XyzTransition>

    <!-- rate -->
    <XyzTransition xyz="fade duration-1">
      <div v-if="currentStep == 11">
        <ScheduleTourFeedback
          :onClickPrev="() => (this.currentStep = 10)"
          :onClickNext="() => (this.currentStep = 12)"
        />
      </div>
    </XyzTransition>

    <!-- Thanks -->
    <XyzTransition xyz="fade">
      <div v-if="currentStep == 12">
        <ScheduleTourThanks />
        <container-buttons
          :textCenter="getTranslation('button_done')"
          :onClickCenter="() => this.closeModal()"
        />
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'add-to-calendar-button'
import { atcb_action } from 'add-to-calendar-button'

import ScheduleTourHome from './ScheduleTourHome.vue'
import ScheduleTourSelfGuided from './ScheduleTourSelfGuided.vue'
import ScheduleTourAvailabilityDates from '@/components/ScheduleTour/ScheduleTourAvailabilityDates.vue'
import ScheduleTourCalendar from '@/components/ScheduleTour/ScheduleTourCalendar.vue';
import ScheduleTourTime from '@/components/ScheduleTour/ScheduleTourTime.vue'
import ScheduleTourFormOne from '@/components/ScheduleTour/ScheduleTourFormOne.vue'
import ScheduleDate from '@/components/Floorplans/ScheduleDate.vue'
import ScheduleTourFormTwo from '@/components/ScheduleTour/ScheduleTourFormTwo.vue'
import ScheduleTourReview from '@/components/ScheduleTour/ScheduleTourReview.vue'
import ScheduleTourConfirm from '@/components/ScheduleTour/ScheduleTourConfirm.vue'
import ScheduleTourRate from '@/components/ScheduleTour/ScheduleTourRate.vue'
import ScheduleTourFeedback from '@/components/ScheduleTour/ScheduleTourFeedback.vue'
import ScheduleTourThanks from '@/components/ScheduleTour/ScheduleTourThanks.vue'
import ContainerButtons from '@/components/Shared/ContainerButtons.vue'
// import AskButton from '@/components/Shared/AskButton.vue'

import moment from 'moment'

export default {
  name: 'ScheduleTourIndex',

  components: {
    ScheduleTourHome,
    ScheduleTourSelfGuided,
    ScheduleTourAvailabilityDates,
    ScheduleTourCalendar,
    ScheduleTourTime,
    ScheduleTourFormOne,
    ScheduleTourFormTwo,
    ScheduleDate,
    ScheduleTourReview,
    ScheduleTourConfirm,
    ScheduleTourRate,
    ScheduleTourFeedback,
    ScheduleTourThanks,
    ContainerButtons
},
  data() {
    return {
      subModal: false,
      mic: false,
      currentStep: 0,
      previousStep: 2, 
      nohideButtons: false,
      showScheduleTourCalendar: false,
      tourForm: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        maxBudget: [750, 4500],
        maxFeet: [750, 4500],
        moveInDate: null,
        bedrooms: [],
        bathrooms: 1,
        notes: '',
        // pets: { label: 'Yes', value: 'yes' },
      },
      date: null,
      time: '',
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    const communityIDs = ["73b6c7c3-ece9-4760-8b32-01c2c16cac1c", "4066738c-1694-4aec-acf0-f296607e79d1"]
    if (!communityIDs.includes(this.community.id_community)) {
    this.currentStep = 2
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapGetters({
      getTranslation: 'translation/getTranslation',
      getCommunity: 'main/getCommunity',
    }),
    scene() {
      return this.getScene
    },
    dateTime() {
      return `${this.date} ${this.time}`
    },
    community() {
      return this.getCommunity
    },
    calendarDate() {
      const eventTime = moment(this.time, 'h:mm A')
      const startTime = eventTime.format('HH:mm')
      const endTime = eventTime.add(15, 'minutes').format('HH:mm')

      return {
        startDate: moment(this.date, 'M/D/YYYY').format('YYYY-MM-DD'),
        startTime,
        endTime,
      }
    },
  },
  methods: {
    ...mapActions({
      setModal: 'main/setModal',
    }),
    showSubModal() {
      this.subModal = true
    },
    handleClickOutside(event) {
      if (this.currentStep !== 5) return
      if (!event || !this.subModal) return
      if (JSON.stringify(event.srcElement) === '{}') this.subModal = false
    },
    scheduleEvent() {
      atcb_action(
        {
          options: ['Google', 'Outlook.com'],
          hideBackground: true,
          listStyle: 'modal',
          buttonStyle: 'round',
          buttonsList: true,
        },
        document.getElementById('btn-schedule-event')
      )
    },
    updateStep(step) {
      this.currentStep = step
    },
    updateTourForm(key, value) {
      this.tourForm[key] = value
    },
    async closeModal() {
      this.setModal(null)
    },
    setDate(date) {
      this.date = date.userText
      console.log("Date selected is ", date)
    },
    setTime(time) {
      this.time = time
    },
     setMoveInDate(date) {
      this.moveInDate = date.userText;
    },
    goToSelfGuidedTour() {
      this.currentStep = 1;
    },
    goToInPersonTour() {
      this.currentStep = 2; 
    },
    goToCalendar() {
      this.updateStep(3);
      this.previousStep = 3;
    },
  },
}
</script>
<style scoped>
@media (max-width: 379px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 14%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}
@media (min-width: 380px) and (max-width: 400px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 20%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}

@media (min-width: 769px) {
  .modals-container {
    text-align: left;
    color: #ffffff;
    height: 100%;
    position: relative;
    background-color: var(--shadow-purple-color);
    padding-top: 20%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
    border-radius: 28px;
  }
}

.button-container {
  width: 100%;
  height: 10%;
  display: flex;
  background-color: var(--primary-color);
}
.left {
  display: flex;
}
.right {
  display: flex;
}

.button-container > div {
  width: 46%;
}

.begin-btn {
  font-size: 15px;
  align-self: center;
}

.subModal {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 220px;
  border-radius: 8px;
}
</style>