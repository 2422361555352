export default {
  namespaced: true,

  state: {
    expandMenu: false  
  },

  getters: {
    getToggleValue(state) {
      return state.expandMenu;
    },
  },

  mutations: {
  toggleExpandMenu(state, payload) {
    state.expandMenu = payload;
    }
  },

  actions: {
  toggleExpandMenu({ commit }, payload) {
    commit('toggleExpandMenu', payload);
  }
},
}