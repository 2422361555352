<template>
    <ModalLayout>
      <template v-slot:title>
        <div style="font-size: 28px">
          choose your <br> tour option
        </div>
      </template>
      <template v-slot:main>
        <div class="container">
        <Button
          :text="'self-guided'"
          style="font-weight: 450; z-index: 3; background-color: var(--tertiary-color); color: var(--primary-color)"
          @click="selfGuidedTour"
        />
        <Button
          :text="'in-person'"
          style="font-weight: 450; z-index: 3; background-color: var(--tertiary-color); color: var(--primary-color)"
          @click="inPersonTour"
        />
      </div> 
    </template>
    </ModalLayout>
</template>
  
<script>
  import ModalLayout from '@/components/Shared/ModalLayout.vue'
  import Button from '@/components/Shared/Button.vue'
  
  export default {
    name: 'ScheduleTourHome',
    components: {
      ModalLayout,
      Button
    },
    methods: {
        selfGuidedTour() {
        this.$emit('selfGuidedSelected');
        },

        inPersonTour() {
        this.$emit('inPersonSelected');
        }
    }
}
</script>
  
<style scoped>
  .content {
      padding: 0 20px;
  }
  .container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 20px; 
    padding-bottom: 10px;  
}
  .container > Button + Button {
    margin-top: 10px; 
}
</style>