<template>
  <ModalLayout>
    <template v-slot:main>
      <div class="static-animation">
        <img src="@/assets/Icons/LoadingAnimation.svg" class="loadingicon"/>
      </div>
      <div class="loader-container">
        <div class="loader" :style="{ width: loaderWidth + '%' }"></div>
      </div>
      <div class="text">
        <span class="get-ready">get ready!</span> <br>
        you are being directed to the self-guided tours scheduling page <br>
        <span class="timer-text">{{ timer }} seconds</span>
      </div>
    </template>
  </ModalLayout>
  <div class="button-wrapper">
    <Button
      class="button"
      :text="'cancel'"
      style="z-index: 3; background-color: var(--tertiary-color); color: var(--primary-color)"
      @click="closeModal" 
    />
  </div>
</template>
  
<script>
import { mapActions } from 'vuex'
import ModalLayout from '../Shared/ModalLayout.vue';
import Button from '../Shared/Button.vue';
  export default {
    name: 'ScheduleTourSelfGuided',
    components: {
      ModalLayout,
      Button
    },
    data() {
      return {
        loaderWidth: 30, // starts from 50%
        timer: '0:07',
        interval: null
      };
    },
    mounted() {
      this.startTimer();
    },
    beforeUnmount() {
      clearInterval(this.interval);
    },
    methods: {
    ...mapActions({
      setModal: 'main/setModal',
    }),
    startTimer() {
        this.interval = setInterval(() => {
        const [minutes, seconds] = this.timer.split(':');
        let totalSeconds = +minutes * 60 + +seconds;
        const increment = (100 - this.loaderWidth) / totalSeconds
        this.loaderWidth += increment;
        totalSeconds--;

        if (totalSeconds < 0) {
        this.timer = '0:00';
        this.loaderWidth = 100;
        clearInterval(this.interval);
        this.closeModal();
        window.open('https://homes.rently.com/apartments-for-rent/Silver%20Collection%20at%20Carl%20D.%20Silver%20Parkway/346?utm_knock_source_title=property_website', '_blank');
        return;
        }
        this.timer = `0:${String(totalSeconds).padStart(2, '0')}`;
        }, 1000);
      },
      async closeModal() {
        this.setModal(null)
      },
    }
  };
  </script>
  
  <style scoped>
  .wrapper :deep(.main) {
    margin: 0 25px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    position: relative;
  }
  .loader-container {
    height: 7px;
    background-color: #e0e0e0;
    margin-top: -52px;
    border-radius: 17px;
  }
  .loader {
    height: 100%;
    background-color: var(--secondary-color);
    transition: width 1s linear;
    border-radius: 17px;
  }
  .text {
    text-align: center;
    margin: 30px 55px;
    font-size: 16px;
  }
  .static-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    text-align: center; 
  }
  .loadingicon {
    width: 160px;
    height: 160px; 
    margin-left: -55px;
    }
  .get-ready {
      display: block;
    }
  .timer-text {
    display: block;
    font-weight: bold; 
    margin-top: 20px; 
  }
  .button-wrapper {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
      padding: 0 20px; 
  }
  .button {
      flex: 1; 
      font-weight: 500;
  }
</style>