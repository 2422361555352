<template>
  <div class="wrapper">
    <ChatHistory 
      id="history"
    />
    <ChatUserInput 
      @microphoneState="handleMicState"
      id="user-input"
    />
  </div>
</template>

<script>

import ChatUserInput from "@/components/Chat/ChatUserInput.vue";
import ChatHistory from "@/components/Chat/ChatHistory.vue";

export default {
  name: 'ChatIndex',

  components: {
    ChatUserInput,
    ChatHistory
  },

  methods: {
    handleMicState(microphoneState) {
      this.$emit('microphoneState', microphoneState); 
    }
  }
}
</script>

<style scoped>
.wrapper{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

#history{
  background-color: var(--white);
  height: 38vh;
}

#user-input{
  height: 12vh;
}

</style>
