import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'

import VueAnimXyz from '@animxyz/vue3'
import '@animxyz/core'

import VueScrollPicker from 'vue-scroll-picker'
import 'vue-scroll-picker/lib/style.css'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Vue3Lottie from 'vue3-lottie'
// import 'vue3-lottie/dist/style.css'

// import './assets/js/smwebsdk/smwebsdk.min'

import VueFeather from 'vue-feather';

const app = createApp(App)

app.use(router)
app.use(VueAnimXyz)
app.use(VueScrollPicker)
app.use(store)
app.use(VueDatePicker)
app.use(Vue3Lottie)
app.component(VueFeather.name, VueFeather);

app.mount('#app')